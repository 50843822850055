import { useAuthContext } from "./hooks/useAuthContext";
import { useMemo } from "react";
import { themeSettings } from "./theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom"
import LoginPage from "scenes/authentication/LoginPage";
import Dashboard from "scenes/dashboard/Dashboard";
import Layout from "scenes/Layout/Layout";
import WinLossReport from "scenes/report/WinLossReport";
import Schedule from "scenes/schedule/Schedule";
import UserManagement from "scenes/user/UserManagement";
import Settings from "scenes/settings/Settings";
import AllReceipt from "scenes/receipt/AllReceipt";
import Numbers from "scenes/numbers/Numbers";
import ChangePassword from "scenes/authentication/ChangePassword";
import CreateNewUser from "scenes/user/CreateNewUser";
import ChangePasswordSuccess from "scenes/authentication/ChangePasswordSuccess";


import useAuth from "hooks/useAuth";
import DownloadCSV from "scenes/csv";


function App() {
    const mode = useSelector((state) => state.global.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    const { user } = useAuthContext()
    const { role } = useAuth()

    console.log("role is")
    console.log(role)

    return (
        <div className="app">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            {/* Public */}
                            {/* <Route index element={!user ? <LoginPage /> : <Navigate to="/dashboard" />} /> */}

                            <Route
                                index
                                element={!user ? (<LoginPage />) : role === "admin" || role === "agent" ? (<Navigate to="/dashboard" />) : (<Navigate to="/subpage" />)}
                            />

                            {/* Protected */}
                            {/* <Route path="user"> */}
                            <Route path="changepassword" element={user ? <ChangePassword /> : <Navigate to="/" />} />
                            {/* </Route> */}

                            {/* Sidebar Route */}
                            {/* <Route path="dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} /> */}

                            <Route
                                path="dashboard"
                                element={
                                    (user && (role === "admin" || role === "agent")) ? (
                                        <Dashboard />
                                    ) : (
                                        <Navigate to="/" />
                                    )
                                }
                            />

                            <Route path="subpage" element={user ? <DownloadCSV /> : <Navigate to="/" />} />
                            <Route path="receipt" element={user ? <AllReceipt /> : <Navigate to="/" />} />
                            <Route path="numbers" element={user ? <Numbers /> : <Navigate to="/" />} />
                            <Route path="report" element={user ? <WinLossReport /> : <Navigate to="/" />} />
                            <Route path="schedule" element={user ? <Schedule /> : <Navigate to="/" />} />
                            {role === 'admin' && (
                                <>
                                    <Route path="usermanagement" element={user ? <UserManagement /> : <Navigate to="/" />} />
                                    <Route path="settings" element={user ? <Settings /> : <Navigate to="/" />} />
                                </>
                            )}
                            <Route path="successchangepassword" element={user ? <ChangePasswordSuccess /> : <Navigate to="/" />} />
                            <Route path="createnewuser" element={user ? <CreateNewUser /> : <Navigate to="/" />} />

                        </Route>
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;