import * as React from 'react';
import axios from '../../utils/helper'
import useAuth from "hooks/useAuth";
import { useAuthContext } from 'hooks/useAuthContext';
import { useState, useRef, useEffect } from "react";
import { useLogout } from "hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { AppBar, IconButton, Toolbar, InputBase, Menu, MenuItem, Box, Typography, useMediaQuery, Button, Modal, TableContainer } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import profileIcon from "../../assets/profile.png"
import searchIcon from "../../assets/search.png"
import FlexBetween from "components/FlexBetween";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import passwordIcon from "../../assets/password.png"
import logoutIcon from "../../assets/logout.png"
import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmationModal } from 'components/Modal';
import '../../index.css'
import '../../css/global.css'
import '../../css/searchDialog.css'

import CloseIcon from '@mui/icons-material/Close';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const Navbar = ({ isSidebarOpen, setIsSidebarOpen, currentLocale, handleChange }) => {

    const intl = useIntl()
    // Decode JWT and Get the username
    const { username } = useAuth()

    const { user } = useAuthContext()

    const isNonMediumScreen = useMediaQuery("(min-width: 1024px)")

    const Navigate = useNavigate()
    const { logout } = useLogout()

    // for search
    const [searchValue, setSearchValue] = useState('')
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)

    const handleSearch = async (searchValue) => {
        try {
            const { data } = await axios.get(`/api/receipt/search?value=${searchValue}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            setOpen(true)
            setData(data)

        } catch (error) {
            console.error(error)
        }
        setSearchValue('')
    }

    // for search result dialog box
    const handleClose = () => {
        setOpen(false)
        setData(null)
    }

    const Dropdown = () => {
        const [anchorEl, setAnchorEl] = useState(null)
        const [isOpen, setIsOpen] = useState(false)
        const ref = useRef(null)

        const [openModal, setOpenModal] = useState(false);

        const handleOpenModal = () => {
            setOpenModal(true);
        };

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
            setIsOpen(!isOpen)
        }

        const handleClose = () => {
            setAnchorEl(null)
            setIsOpen(false)
        }

        const handleChangePassword = () => {
            Navigate('/changepassword')
        }

        const handleLogout = () => {
            Navigate('/')
            logout()
        }

        const handleNoLogout = () => {
            setOpenModal(false)
        }

        const languageButtonStyle = {
            flexGrow: 1,
            backgroundColor: '#92adf0',
            color: 'white'
        }

        useEffect(() => {
            const handleOutsideClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClose()
                }
            }

            document.addEventListener("mousedown", handleOutsideClick)

            return () => {
                document.removeEventListener("mousedown", handleOutsideClick)
            }
        }, [ref])

        return (
            <FlexBetween ref={ref} gap="1.5rem">

                {/* dropdown for setting and logout */}
                <FlexBetween
                    backgroundColor="#E6EBF8"
                    borderRadius="9px"
                    gap="1.5rem"
                    p={isNonMediumScreen ? "0.5rem 1.5rem" : "0.3rem 0.75rem"}       //top&bottom, left&right
                >
                    {isNonMediumScreen ? (
                        <FlexBetween onClick={handleClick}>
                            <img src={profileIcon} alt="profile" style={{ width: '1.2rem', marginRight: '0.5rem' }} />
                            {/* Display the username */}
                            <span style={{ color: '#707070' }}>{username}</span>
                            <FlexBetween style={{ width: '2rem', justifyContent: 'center' }}>
                                {isOpen ? <KeyboardArrowUpIcon style={{ color: '#707070' }} onClick={() => handleClick()} /> : <KeyboardArrowDownIcon style={{ color: '#707070' }} onClick={() => handleClose()} />}
                            </FlexBetween>
                        </FlexBetween>
                    ) : (
                        <FlexBetween onClick={handleClick}>
                            <Box>
                                <img src={profileIcon} alt="profile" style={{ width: '1.2rem', marginRight: '0.5rem' }} />
                            </Box>
                            <Box>
                                {isOpen ? <KeyboardArrowUpIcon style={{ color: '#707070' }} onClick={() => handleClick()} /> : <KeyboardArrowDownIcon style={{ color: '#707070' }} onClick={() => handleClose()} />}
                            </Box>
                        </FlexBetween>
                    )}

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Box display='flex' padding='0 5px'>
                            <Button
                                style={currentLocale === 'en' ? languageButtonStyle : { ...languageButtonStyle, backgroundColor: 'transparent', cursor: 'not-allowed', color: 'black' }}
                                onClick={(e) => handleChange(e.target.value)}
                                disabled={currentLocale === 'en'}
                                value="en"
                            >
                                En
                            </Button>
                            <Button
                                style={currentLocale === 'zh' ? languageButtonStyle : { ...languageButtonStyle, backgroundColor: 'transparent', cursor: 'not-allowed', color: 'black' }}
                                onClick={(e) => handleChange(e.target.value)}
                                disabled={currentLocale === 'zh'}
                                value="zh"
                            >
                                Zh
                            </Button>
                        </Box>
                        <MenuItem onClick={handleChangePassword}>
                            <img src={passwordIcon} alt="search" style={{ width: '1.15rem', marginRight: '10px' }} />
                            <FormattedMessage id="change.password" />
                        </MenuItem>
                        <MenuItem onClick={handleOpenModal}>
                            <img src={logoutIcon} alt="search" style={{ width: '1.15rem', marginRight: '10px' }} />
                            <FormattedMessage id="logout" />
                        </MenuItem>

                    </Menu>

                    {openModal && (
                        <ConfirmationModal
                            message="logout.confirmation.message"
                            onLogout={handleLogout}
                            onCancel={handleNoLogout}
                            onClose={handleNoLogout}
                        />
                    )}

                </FlexBetween>
            </FlexBetween>
        )
    }


    return <AppBar
        sx={{
            position: "fixed",
            width: "100%",
            boxShadow: "none",
            backgroundColor: "#FFFFFF",
            p: "0.5rem"
        }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
            {/* left side */}
            <FlexBetween sx={{ marginLeft: isNonMediumScreen && isSidebarOpen ? '233px' : '0' }}>

                {/* open close sidebar */}
                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <MenuIcon />
                </IconButton>

                <FlexBetween color="#4385EA">
                    <Box display="flex" alignItems="center" gap="0.5rem">
                        <Typography variant='h4' fontWeight="bold">
                        </Typography>
                    </Box>
                </FlexBetween>

            </FlexBetween>


            {/* right side */}
            <FlexBetween gap="1rem" >

                {/* search */}
                <FlexBetween
                    backgroundColor="#9A9B9D17"
                    borderRadius="9px"
                    gap={isNonMediumScreen ? "0.5rem" : "0"}
                    p={isNonMediumScreen ? "0.1rem 1.5rem" : "0 0rem"}       //top&bottom, left&right
                >
                    <IconButton
                        type='button'
                        onClick={() => handleSearch(searchValue)}
                    >
                        <img src={searchIcon} alt="search" style={{ width: '1.2rem' }} />
                    </IconButton>
                    <InputBase
                        placeholder={intl.formatMessage({ id: 'search' })}
                        inputProps={{ style: { color: "#88898C" } }}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </FlexBetween>


                {/* search output dialog box */}
                <Box>
                    <Modal open={open} onClose={handleClose}>
                        {data ? (
                            <Box>
                                {data && data.playerID !== undefined ? (
                                    <Box className="search-dialog-box">
                                        <Box className="searchBox-header">
                                            <Box flexGrow={1} paddingLeft="40px" paddingBottom="15px" paddingTop="15px">
                                                <Typography color='green'><FormattedMessage id='receiptID' />: {data.playerID}</Typography>
                                            </Box>
                                            <IconButton>
                                                <CloseIcon onClick={handleClose} />
                                            </IconButton>
                                        </Box>
                                        <Box className='search-details'>

                                            {/* table here */}
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='receiptID' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className='searchValue'>{data._id}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='dateTime' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className='searchValue'>{new Date(data.createdAt).getDate()}-{new Date(data.createdAt).toLocaleString('default', { month: 'short' })}-{new Date(data.createdAt).getFullYear()}, {new Date(data.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='agent' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className='searchValue'>{data.importBy}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='betID' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className='searchValue'>{data.playerID}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='betUnit' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className='searchValue'>{data.luckyUnit}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='betAmount' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className='searchValue'>{data.luckyAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="borderedRow">
                                                            <TableCell className="tableCellWithBorder">
                                                                <Typography className='searchTitle'><FormattedMessage id='number' /></Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div className='searchValueContainer'>
                                                                    <Typography className='searchValue' >
                                                                        {data.luckyNum.map((number) => (<div key={number}>{number}</div>))}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>

                                        {/* <Divider /> */}

                                        <Box className="ok-box">
                                            <Button variant="contained" className="ok-button" onClick={handleClose}>
                                                ok
                                            </Button>
                                        </Box>

                                    </Box>
                                ) : (
                                    <Box className="search-dialog-box">
                                        <Box className="searchBox-header">
                                            <Box flexGrow={1} paddingLeft="40px" paddingBottom="15px" paddingTop="15px">
                                                <Typography color='green'> {data.playerID}</Typography>
                                            </Box>
                                            <IconButton>
                                                <CloseIcon onClick={handleClose} />
                                            </IconButton>
                                        </Box>
                                        <Box className='search-details'>

                                            <Typography variant="h5"><FormattedMessage id='number.available' /></Typography>
                                        </Box>

                                        {/* <Divider /> */}

                                        <Box className="ok-box-center">
                                            <Button variant="contained" className="ok-button" onClick={handleClose}>
                                                ok
                                            </Button>
                                        </Box>

                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Typography variant="h5"><FormattedMessage id='no.record' /></Typography>
                        )}
                    </Modal>
                </Box>

                {/* user setting dropdown */}
                <Dropdown />

            </FlexBetween>
        </Toolbar>
    </AppBar>
}

export default Navbar