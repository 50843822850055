const { Box } = require("@mui/material");
const { default: styled } = require("styled-components");


const FlexBetween = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
})

export default FlexBetween