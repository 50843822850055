import { LOCALES } from "./locales"

export const messages = {
    [LOCALES.ENGLISH]: {
        "super6d": "SUPER 6D",
        "login.username": "Username",
        "login.password": "Password",
        "login": "Login",
        "login.captcha": "Captcha",
        "login.username.error": "Please enter the username",
        "login.password.error": "Please enter the password",
        "login.captcha.error": "Please enter the captcha",
        "login.failed.title": "Error",
        "login.failed.message": "These credentials does not match with our records",
        "logout": "Logout",
        "logout.confirmation.message": "Are you sure to Logout?",
        "logout.yes": "Yes",
        "logout.cancel": "Cancel",

        // search
        "no.record": "No such record",
        "receiptID": "Receipt ID",
        "dateTime": "Bet Date & Time",
        "drawDate": "Draw Date",
        "agent": "Agent",
        "betID": "Bet ID",
        "betUnit": "Bet Unit",
        "betAmount": "Bet Amount",
        "6dnumber": "Number(s)",
        "number.available": "This number is still available",

        // sidebar
        "dashboardNav": "Dashboard",
        "receiptNav": "Receipt",
        "scheduleNav": "Schedules",
        "winLossReportNav": "Win/Loss Report",
        "userManagementNav": "User Management",
        "settingsNav": "Setting",

        // dashboard
        "dashboard": "Dashboard",
        "today": "Today",
        "from": "From:",
        "to": "To:",
        "search": "Search",
        "refresh": "Refresh",
        "all.time": "All Time",
        "this.month": "This Month",
        "last.week": "Last Week",
        "this.week": "This Week",
        "yesterday": "Yesterday",
        "today": "Today",
        "accumulated.prize.pool": "Accumulated Prize Pool",
        "active.user": "Active Users",
        "unit.sold": "Units Sold",
        "strike": "Strike",
        "sales": "Sales",
        "commission": "Commission to Agent",
        "jackpot": "Profit into Jackpot Pool",
        "profit": "Nett Profit",
        "orders": "Orders",
        "total.units": "Total Units",
        "sold.units": "Sold Units",
        "unsold.units": "Unsold Units",
        "top": "Top 10",
        "rank": "Rank",
        "platform": "Platform",
        "player": "Bet ID",
        "top1": "Top 1",
        "top2": "Top 2",
        "top3": "Top 3",
        "top4": "Top 4",
        "top5": "Top 5",
        "top6": "Top 6",
        "top7": "Top 7",
        "top8": "Top 8",
        "top9": "Top 9",
        "top10": "Top 10",

        // receipt
        "receipt": "Receipt",
        "agent.platform": "Agent",
        "username": "Bet ID",
        "id": "Receipt ID",
        "number": "6D Number",
        "bet.date": "Bet Date",
        "draw.date": "Draw Date",
        "detail.button": "Details",
        "total": "Total Amount",

        // numbers
        "schedules": "Schedules",
        "sold.numbers": "Sold Numbers",
        "unsold.numbers": "Unsold Numbers",

        // winloss report
        "win.loss.report": "Win/Loss Report",
        "total.bet.unit": "Total Bet (Unit)",
        "total.bet.rm": "Total Bet (RM)",
        "commission.expenses.rm": "Commission Expenses (RM)",
        "jackpot.expenses.rm": "Money into Jackpot Pool (RM)",
        "total.strike": "Strike (RM)",
        "total.balance.rm": "Balance (RM)",

        // user management
        "user.management": "User Management",
        "add.agent": "Add Agent",
        "status": "Status",
        "action": "Action",
        "deactivate.button": "Deactivate",
        "activate.button": "Activate",
        "deactivate.message": "Are you sure to Deactivate this user?",
        "activate.message": "Are you sure to Activate this user?",
        "status.active": "ACTIVE",
        "status.suspended": "SUSPENDED",
        "fail": "Duplicate account found. Please try again using a different username.",
        "success": "Account has been created successfully.",
        "createUser.nameError": "Only digits and alphabets are allowed",
        // "create.usersuccessful": "User has been successfully created",
        // "create.userfailed": "fail",

        // create new user
        "create.new.user": "Create New User",
        "new.agent.username": "Enter New Agent Username",
        "new.agent.password": "Enter Password",
        "save": "Save",

        // setting
        "setting": "Settings",
        "prize.pool.range": "Prize Pool Range",
        "select.date": "Select Date",
        "prize.pool.amount": "Enter Prize Pool Amount (RM)",
        "jackpot.expenses": "Enter Jackpot Expenses (%)",
        "sixd.result": "6D Results",
        "go": "Go",
        "enter.sixd.result": "Enter 6D Results",
        "prizePoolDateSuccess": "ok",
        "prizePoolDateFailed": "This draw date is unavailable",
        "6dDateSuccess": "ok",
        "6dDateFailed": "This draw date is unavailable",
        "6dFieldSuccess": "Saved.",
        "sixDfailedMessage": "Invalid 6D result. Plaease check again",

        // change password
        "change.password": "Change Password",
        "current.password": "Current Password",
        "new.password": "New Password",
        "confirm.password": "Confirm Password",
        "success.message": "Your password has been changed successfully.",
        "go.to.home": "Go to home",
        "current.empty": "Please enter your current password.",
        "new.empty": "Please enter your new password",
        "confirm.empty": "Please confirm your new password.",
        "confirm.failed": "Your password does not match.",


        // schedule
        "draw.date": "Draw Date",
        "sixd.num": "6D Number",
        "into.jackpot.pool.percentage": "Jackpot Expenses",
        "into.jackpot.pool.rm": "Amount",
        "available.button": "Available",
        "unavailable.button": "Unavailable",
        "new.draw.date": "Add New Draw Date",
        "next": "Next Draw Date",
        "add": "Add",
        "record": "Record",
        "log": "Log",
        "activity": "Activity",
        "delete": "Delete",
        "delete.confirmation.message": "Are you sure you want to delete?",
        "delete.yes": "Yes",
        "delete.cancel": "No",
        "available.message": "Are you sure you want to set available?",
        "unavailable.message": "Are you sure you want to set unavailable?",
        "log.dateTime": "Time",
        "before": "Value Before Change",
        "after": "Value After Change",
        "number": "No",
        "no.log.record": "No Log Record",
        "payout": "Jackpot Pool Price",
        "expenses": "Profit into Jackpot Pool",
        "drawNum": "6D Strike Number",

        // csv
        'csv.title': "Download CSV",
        'export.csv': "Export",
        'date': "Date",
    },


    [LOCALES.CHINESE]: {
        "super6d": "SUPER 6D",
        "login.username": "登录名",
        "login.password": "密码",
        "login": "登录",
        "login.captcha": "验证码",
        "login.username.error": "请输入用户登录名",
        "login.password.error": "请输入登录密码",
        "login.captcha.error": "请输入验证码",
        "login.failed.title": "登陆失败",
        "login.failed.message": "您的用户名/密码不匹配。",
        "logout": "退出",
        "logout.confirmation.message": "确定要登出吗?",
        "logout.yes": "是",
        "logout.cancel": "否",

        // search
        "no.record": "无此纪录",
        "receiptID": "单据 ID",
        "dateTime": "下注时间",
        "drawDate": "开彩日期",
        "agent": "代理平台",
        "betID": "下注用户名",
        "betUnit": "下注单位",
        "betAmount": "下注总金额",
        "6dnumber": "下注号码",
        "number.available": "此号码还未售出",


        // sidebar
        "dashboardNav": "仪表盘",
        "receiptNav": "单据记录",
        "scheduleNav": "开彩日记录",
        "winLossReportNav": "赢/输报告",
        "userManagementNav": "用户管理",
        "settingsNav": "设置",

        // dashboard
        "dashboard": "仪表盘",
        "today": "今天",
        "from": "从:",
        "to": "至:",
        "search": "搜索",
        "refresh": "刷新",
        "all.time": "全部",
        "this.month": "这个月",
        "last.week": "上个星期",
        "this.week": "这个星期",
        "yesterday": "昨天",
        "today": "今天",
        "accumulated.prize.pool": "累计奖金池",
        "active.user": "活跃用户",
        "unit.sold": "售出单位",
        "strike": "中奖",
        "sales": "销售金额",
        "commission": "佣金支出",
        "jackpot": "奖金池支出",
        "profit": "总盈利",
        "orders": "购买量",
        "total.units": "总单位",
        "sold.units": "售出单位",
        "unsold.units": "未售出单位",
        "top": "前十名下注排行榜",
        "rank": "排行榜",
        "platform": "平台",
        "player": "下注 ID",
        "top1": "第一名",
        "top2": "第二名",
        "top3": "第三名",
        "top4": "第四名",
        "top5": "第五名",
        "top6": "第六名",
        "top7": "第七名",
        "top8": "第八名",
        "top9": "第九名",
        "top10": "第十名",

        // receipt
        "receipt": "单据",
        "agent.platform": "代理平台",
        "username": "下注用户名",
        "id": "单据 ID",
        "number": "6D 号码",
        "bet.date": "下注日期",
        "draw.date": "开彩日期",
        "detail.button": "资料",
        "total": "总价",

        // numbers
        "schedules": "开彩日",
        "sold.numbers": "已售出号码",
        "unsold.numbers": "未售出号码",

        // winloss report
        "win.loss.report": "赢输报告",
        "total.bet.unit": "总下注 (单位)",
        "total.bet.rm": "总下注 (RM)",
        "commission.expenses.rm": "佣金支出 (RM)",
        "jackpot.expenses.rm": "奖金池支出 (RM)",
        "total.strike": "总中奖 (RM)",
        "total.balance.rm": "余额 (RM)",

        // user management
        "user.management": "用户设置",
        "add.agent": "增添新代理账号",
        "status": "活跃状态",
        "action": "行动",
        "deactivate.button": "停用",
        "activate.button": "开启",
        "deactivate.message": "确定要停用此账号吗?",
        "activate.message": "确定要激活此账号吗?",
        "status.active": "活跃",
        "status.suspended": "上锁",
        "fail": "发现到重复的代理用户名，请用新名再重试",
        "success": "成功开启代理用户账号。",
        "createUser.nameError": "只接受号码或字母组合。",
        // "create.user.successful": "用户已成功开账号",
        // "create.user.failed": "失败",

        // create new user
        "create.new.user": "增添代理账号",
        "new.agent.username": "代理用户名",
        "new.agent.password": "代理密码",
        "save": "储存",

        // setting
        "setting": "设置",
        "prize.pool.range": "奖金池",
        "select.date": "日期",
        "prize.pool.amount": "输入奖金池金额 (RM)",
        "jackpot.expenses": "输入奖金池占比 (%)",
        "sixd.result": "6D 开彩号码",
        "go": "前往",
        "enter.sixd.result": "输入 6D 开彩号码",
        "prizePoolDateSuccess": "ok",
        "prizePoolDateFailed": "不存在此开奖日期",
        "6dDateSuccess": "ok",
        "6dDateFailed": "不存在此开奖日期",
        "6dFieldSuccess": "已存",
        "sixDfailedMessage": "6D 号码不正确，请再检查。",

        // change password
        "change.password": "更换密码",
        "current.password": "当前密码",
        "new.password": "新密码",
        "confirm.password": "确定新密码",
        "success.message": "密码已成功被更换。",
        "go.to.home": "首页",
        "current.empty": "请输入您现在的密码.",
        "new.empty": "请输入您的新密码",
        "confirm.empty": "请确认您的新密码",
        "confirm.failed": "您输入的密码不正确",

        // schedule
        "draw.date": "开彩日",
        "sixd.num": "6D 开彩号码",
        "into.jackpot.pool.percentage": "奖金池支出占比",
        "into.jackpot.pool.rm": "奖金池支出 (RM)",
        "available.button": "活跃",
        "unavailable.button": "不可使用",
        "new.draw.date": "添加新开彩日",
        "next": "下期开彩日",
        "add": "添加",
        "record": "记录",
        "log": "更动记录",
        "activity": "更动事项",
        "delete": "删除",
        "delete.confirmation.message": "您确定要删除吗？",
        "delete.yes": "是",
        "delete.cancel": "否",
        "available.message": "您确定要设置为可使用吗?",
        "unavailable.message": "您确定要设置为不可使用吗?",
        "log.dateTime": "时间",
        "before": "之前",
        "after": "之后",
        "number": "No",
        "no.log.record": "无更动记录",
        "payout": "奖金池总金额",
        "expenses": "进入奖金池的占比",
        "drawNum": "6D 开彩号码",

        // csv
        'csv.title': "下载数据",
        'export.csv': "导出",
        'date': "日期",
    },
}