import { Box, Typography } from "@mui/material"
import Form from "./LoginForm"
import '../../css/loginPage.css'
import Logo from '../../assets/gmail_logo.png'


const LoginPage = () => {

    return (
        <Box className="container">
            <Box className="title-component" >
                <Typography className="title-text" >
                    <img src={Logo} alt="Logo" className="logo" width="100px" height="80px" />
                </Typography>
            </Box>

            <Box className="login-form-component" >
                <Form />
            </Box>
        </Box>
    )
}

export default LoginPage