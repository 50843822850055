import { Box, useMediaQuery } from "@mui/material"
import Header from "components/Header"
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import '../../css/global.css'
import { FormattedMessage, useIntl } from "react-intl";

import { ConfirmationModal } from 'components/Modal';
import { useAuthContext } from "hooks/useAuthContext";
import axios from '../../utils/helper'

const UserManagement = () => {

    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")

    const { user } = useAuthContext()
    const navigate = useNavigate()

    const intl = useIntl()
    const activateButton = intl.formatMessage({ id: 'activate.button' })
    const deactivateButton = intl.formatMessage({ id: 'deactivate.button' })
    const activateMessage = intl.formatMessage({ id: 'activate.message' })
    const deactivateMessage = intl.formatMessage({ id: 'deactivate.message' })
    const statusActive = intl.formatMessage({ id: 'status.active' })
    const statusSuspended = intl.formatMessage({ id: 'status.suspended' })

    const [openModal, setOpenModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState('')
    const [selectedId, setSelectedId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null)

    const handleOpenModal = (id, status, action) => {
        setSelectedId(id)
        setSelectedStatus(status)
        setSelectedAction(action)
        setOpenModal(true);
    };

    const handleDeactivate = async () => {
        try {
            const { data } = await axios.patch(`/api/user`, {
                id: selectedId,
                status: selectedStatus
            }, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` },
            })

            setOpenModal(false)
            window.location.reload()

        } catch (error) {
            console.error(error)
        }
    }

    const handleNoDeactivate = () => {
        setOpenModal(false);
    }

    const [userList, setUserList] = useState([])


    const [pageState, setPageState] = useState({
        isLoading: false,
        user: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const fetchUserList = async () => {
        try {

            setPageState(old => ({...old, isLoading: true}))

            const {data} = await axios.get(`/api/user?page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` },
            })

            const userWithIndex = data.findUser.map((user, index) => ({
                ...user,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, user: userWithIndex, total: data.pageTotal }))

            // const { data } = await axios.get(`/api/user`, {
            //     headers: { 'Authorization': `Bearer ${user.accessToken}` },
            // })
            // const userListWithIndex = data.map((user, index) => ({
            //     ...user,
            //     rowIndex: index + 1
            // }))
            // setUserList(userListWithIndex)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchUserList()
    }, [pageState.page, pageState.pageSize])

    // const getRowId = (row) => row._id

    const columnsReceipt = [
        {
            field: 'rowIndex',
            headerName: 'No',
            flex: 1,
            minWidth: 90,
        },
        {
            field: 'username',
            headerName: <FormattedMessage id="agent.platform" />,
            flex: 2,
            minWidth: 120,
            editable: false,
        },
        {
            field: 'status',
            headerName: <FormattedMessage id="status" />,
            valueGetter: (params) =>
                (params.row.status ? statusActive : statusSuspended),
            cellClassName: (params) =>
                (params.value === statusSuspended ? "status-suspended" : "name-column--cell")
            ,
            flex: 2,
            minWidth: 120,
            editable: false,
        },
        {
            field: 'action',
            headerName: <FormattedMessage id="action" />,
            flex: 2,
            minWidth: 120,
            editable: false,
            renderCell: (params) => (
                <Button
                    className={params.row.status ? 'deactivate-button' : 'activate-button'}
                    variant="contained"
                    onClick={() => handleOpenModal(params.row._id, params.row.status, params.row.status ? 'deactivate' : 'activate')}
                    startIcon={params.row.status ? <CancelIcon /> : <CheckCircleOutlineIcon />}
                >
                    {params.row.status ? deactivateButton : activateButton}
                </Button>
            ),
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="user.management" />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box gridColumn="span 12" height="fit-content" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button className="add-button" variant="contained"
                        onClick={() => {
                            navigate(`/createnewuser`);
                        }}
                    >
                        <FormattedMessage id="add.agent" />
                    </Button>
                </Box>

                <Box
                    sx={{
                        gridColumn: "span 12",
                        height: "75vh",
                        backgroundColor: "#FFFFFF",
                        p: "2rem",
                        borderRadius: "0.55rem",
                        className: "default-section",
                        "& .status-suspended": {
                            color: "red"
                        }
                    }}
                >
                    <DataGrid
                        columns={columnsReceipt}
                        rows={pageState.user}
                        // getRowId={getRowId}
                        getRowId={(row) => row._id}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10]}
                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                        sx={{
                            border: 1,
                            borderColor: 'primary.light',
                            textAlign: 'left',
                            justifyContent: 'left',
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: '#f7f3e9',
                            },
                            [`& .${gridClasses.row}.even`]: {
                                backgroundColor: '#2A75EA1C'
                            },
                            '& .MuiDataGrid-columnHeader': {
                                borderBottom: '1px solid #2A75EA'
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                    />

                    {openModal && (
                        <ConfirmationModal
                            message={selectedAction === 'deactivate' ? deactivateMessage : activateMessage}
                            onLogout={handleDeactivate}
                            onCancel={handleNoDeactivate}
                            onClose={handleNoDeactivate}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default UserManagement