import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
// Ori
import App from './App';
// V2
// import App from './App_v2';
import { configureStore } from '@reduxjs/toolkit'
import globalReducer from "state"
// import { setupListeners } from "@reduxjs/toolkit/query";
// import { api } from "state/api";
import { AuthContextProvider } from './context/authContext';

const store = configureStore({
  reducer: {
    global: globalReducer,
  },
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>
);