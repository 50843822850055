import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import Button from '@mui/material/Button';
import Header from 'components/Header';
import { Box, FormLabel, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { useAuthContext } from 'hooks/useAuthContext';
import { FormattedMessage } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { CSVLink, CSVDownload } from "react-csv";


const DownloadCSV = () => {

    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")
    const { user } = useAuthContext()
    const [drawDate, setDrawDate] = useState(new Date())

    const [download, setDownload] = useState([])

    const handleSearch = async () => {
        try {
            const formatStart = moment(drawDate).format('YYYY-MM-DD')

            console.log(drawDate)
            console.log(formatStart)

            const { data } = await axios.get(`/api/receipt/test?drawDate=${formatStart}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            console.log(drawDate)
            console.log(data)

            setDownload(data)

        } catch (error) {
            console.log(error)
        }
    }

    const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '0.5px solid #A5CDF7',
                borderRadius: '0.3rem',
                width: '13rem',
                height: '2rem',
                padding: '0.2rem 0.5rem',
                fontSize: '13px',
                color: '#474747',
                fontWeight: 500,
                alignItems: 'center',
            }}
            onClick={onClick}
        >
            <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#2A75EA', marginRight: '4px' }} />
            <span style={{}}>{value}</span>
            {value && (
                <ClearIcon
                    sx={{ fontSize: '20px', color: '#999', cursor: 'pointer' }}
                    onClick={onClear}
                />
            )}
        </Box>
    ));

    const handleStartDate = () => {
        setDrawDate(null)
    }

    const headers = [
        { label: 'Player ID', key: 'playerID' },
        { label: 'Lucky Number', key: 'luckyNum' },
        { label: 'Count', key: 'count' },
        { label: 'draw Date', key: 'drawDate' },
    ];

    const data = download.map(downloadData => {
        return {
            playerID: downloadData.playerID,
            luckyNum: downloadData.luckyNum,
            count: downloadData.count,
            drawDate: downloadData.drawDate,
            // createdAt: new Date(downloadData.createdAt).toLocaleString('en-US', {
            //     year: 'numeric',
            //     month: '2-digit',
            //     day: '2-digit',
            //     hour: 'numeric',
            //     minute: 'numeric',
            //     second: 'numeric',
            //     hour12: true,
            // }),
        };
    });

    const CSVFileName = new Date(data[0]?.drawDate);
    const CSVformattedDate = `${CSVFileName.getDate().toString().padStart(2, '0')}${(CSVFileName.getMonth() + 1).toString().padStart(2, '0')}${CSVFileName.getFullYear().toString().slice(2)}_${CSVFileName.getHours().toString().padStart(2, '0')}${CSVFileName.getMinutes().toString().padStart(2, '0')}${CSVFileName.getSeconds().toString().padStart(2, '0')}`;
    const CSVformattedTime = `${CSVFileName.getHours().toString().padStart(2, '0')}${CSVFileName.getMinutes().toString().padStart(2, '0')}${CSVFileName.getSeconds().toString().padStart(2, '0')}`;


    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="csv.title" />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" },
                    '& .positive-value': {
                        color: 'green',
                    },
                    '& .negative-value': {
                        color: 'red',
                    },
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="2rem"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >
                    <Box pb="2rem">
                        <Box className="details-component">
                            <Box className="input-field">
                                <FormLabel className="field-label"><FormattedMessage id="date" /> </FormLabel>
                                <DatePicker
                                    selected={drawDate}
                                    onChange={date => setDrawDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomInput onClear={handleStartDate} />}
                                />
                            </Box>
                            <Box className="input-field">
                                <Button className="search-button" variant="contained" onClick={handleSearch}><FormattedMessage id="search" /></Button>
                           
                                {download.length > 0 ? (
                                    <Box>
                                        <CSVLink
                                            data={download}
                                            headers={headers}
                                            filename={`Super6D_${CSVformattedDate}_${CSVformattedTime}.csv`}
                                        >
                                            <Button
                                                className="search-button"
                                                variant="contained"
                                                onClick={handleSearch}
                                            >
                                                <FormattedMessage id="export.csv" />
                                            </Button>
                                        </CSVLink>
                                    </Box>
                                ) : (
                                    <Typography></Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default DownloadCSV