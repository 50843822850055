import axios from '../../utils/helper'
import moment from "moment";
import React, { useState } from "react"
import { useAuthContext } from "hooks/useAuthContext";
import { Box, Button, TextField, Typography } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from 'react-hook-form'
import Header from "components/Header"
import DatePicker from 'react-datepicker'
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import NewUserModal from "components/CreateUserModal";
import '../../css/setting.css'
import '../../css/global.css'


const Settings = () => {

    const { user } = useAuthContext()

    const [selectedSixdResultDate, setSelectedSixdResultDate] = useState(new Date());
    const [selectedPrizePoolDate, setSelectedPrizePoolDate] = useState(new Date());

    const { control: controlPrizePoolDate, handleSubmit: handleSubmitPrizePoolDate } = useForm()
    const { control: controlPrizePool, handleSubmit: handleSubmitPrizePool, setValue } = useForm()
    const { control: controlSixdResultDate, handleSubmit: handleSubmitSixdResultDate } = useForm({})
    const { control: controlSixdResult, handleSubmit: handleSubmitSixdResult, setValue: setResult } = useForm({})

    // for open and close modal
    const [status, setStatus] = useState(null)
    const intl = useIntl()
    const prizePoolDateSuccessMessage = intl.formatMessage({ id: 'prizePoolDateSuccess' })
    const prizePoolDateFailedMessage = intl.formatMessage({ id: 'prizePoolDateFailed' })
    const sixDdateSuccessMessage = intl.formatMessage({ id: '6dDateSuccess' })
    const sixDdateFailedMessage = intl.formatMessage({ id: '6dDateFailed' })
    const sixDFieldSuccessMessage = intl.formatMessage({ id: '6dFieldSuccess' })
    const sixDFieldFailedMessage = intl.formatMessage({ id: 'sixDfailedMessage' })

    const [openPrizePoolDateModal, setOpenPrizePoolDateModal] = useState(false)
    const handleOpenPrizePoolDateModal = () => {
        setOpenPrizePoolDateModal(true)
    }

    const [opensixDdate, setOpensixDdate] = useState(false)
    const handleOpensixDdate = () => {
        setOpensixDdate(true)
    }

    const [opensixDField, setOpensixDField] = useState(false)
    const handleOpensixDField = () => {
        setOpensixDField(true)
    }

    const handleOK = () => {
        setOpenPrizePoolDateModal(false)
        setOpensixDdate(false)
        setOpensixDField(false)
    }

    // Schedule Go Button
    const handleSavePrizePoolDate = async (date) => {
        try {
            const drawDate = moment(date.selectedPrizePoolDate).format('YYYY-MM-DD')

            const { data } = await axios.get(`/api/schedule?drawDate=${drawDate}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            setValue('prizePoolAmount', data.payout)
            setValue('jackpotExpenses', data.expenses)

            setStatus('success')

        } catch (error) {
            console.error(error)

            setStatus('error')
            handleOpenPrizePoolDateModal('error')

            setValue('prizePoolAmount', '')
            setValue('jackpotExpenses', '')
        }
    }

    // Schedule Save Button
    const handleSavePrizePool = async (schedule) => {
        try {

            const { data } = await axios.patch(`/api/schedule/update`, {
                drawDate: moment(selectedPrizePoolDate).format('YYYY-MM-DD'),
                payout: schedule.prizePoolAmount,
                expenses: schedule.jackpotExpenses
            }, { headers: { 'Authorization': `Bearer ${user.accessToken}` } })

            console.log("HELLO is")
            console.log(data)

        } catch (error) {
            // TODO: Handle Error Not YET
            console.error(error)

        }
    }

    // 6d results date go button
    const handleSaveSixdResultDate = async (date) => {
        try {
            const drawDate = moment(date.selectedSixdResultDate).format('YYYY-MM-DD')

            const { data } = await axios.get(`/api/strike/result?drawDate=${drawDate}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` },
            })

            setResult('SixdResult', data.drawNum)
            setStatus('success')

        } catch (error) {
            console.error(error)
            setStatus('error')
            handleOpensixDdate('error')
        }
    }

    // second 6d results save
    const handleSaveSixdResult = async (number) => {
        try {
            const { data } = await axios.put(`/api/strike/update`, {
                drawDate: moment(selectedSixdResultDate).format('YYYY-MM-DD'),
                drawNum: number.SixdResult
            }, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` },
            })
            setStatus('success')
            handleOpensixDField('success')
        } catch (error) {
            console.error(error)
            setStatus('error')
            handleOpensixDField('error')
        }
    }

    const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '0.5px solid #A5CDF7',
                borderRadius: '0.3rem',
                width: '12rem',
                height: '2rem',
                padding: '0.2rem 0.5rem',
                fontSize: '13px',
                color: '#474747',
                fontWeight: 500,
                alignItems: 'center',
            }}
            onClick={onClick}
        >
            <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#2A75EA', marginRight: '4px' }} />
            <span style={{}}>{value}</span>
            {value && (
                <ClearIcon
                    sx={{ fontSize: '20px', color: '#999', cursor: 'pointer' }}
                    onClick={onClear}
                />
            )}
        </Box>
    ));

    // for datepicker
    const handlePrizePoolDate = () => {
        setSelectedPrizePoolDate(null)
    }

    // for datepicker
    const handleSixdResultDate = () => {
        setSelectedSixdResultDate(null)
    }

    return (
        <Box m="1.5rem 1.5rem" >
            <Header title={<FormattedMessage id="setting" />} />

            <Box
                mt="20px"
                gap="20px"
                paddingBottom="20px"
            >
                {/* first prize pool form */}
                <Box className='default-section'>
                    <form onSubmit={handleSubmitPrizePoolDate(handleSavePrizePoolDate)}>
                        <Box borderBottom="1px solid #e3e4e6" style={{ width: "100%" }}>
                            <Typography className="section-title">
                                <FormattedMessage id="prize.pool.range" />
                            </Typography>
                        </Box>

                        <Box className='sixd-datefield'>
                            <Box className='sixd-component'>
                                <label className='calendar-label'><FormattedMessage id="select.date" />:</label>
                                <Controller
                                    control={controlPrizePoolDate}
                                    name="selectedPrizePoolDate"
                                    defaultValue={selectedPrizePoolDate}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <DatePicker
                                            onChange={(date) => {
                                                const dateString = moment(date).format("YYYY-MM-DD")
                                                onChange(dateString)
                                                setSelectedPrizePoolDate(dateString); // update selected date
                                            }}
                                            onBlur={onBlur}
                                            selected={value ? new Date(value) : null}
                                            showTimeSelect={false}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<CustomInput onClear={handlePrizePoolDate} />}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className='sixd-component'>
                                <label className="transparent"> cheat </label>
                                <Button type="submit" className="go-button" variant='contained'><FormattedMessage id="go" /></Button>
                            </Box>

                        </Box>
                    </form>
                    <form onSubmit={handleSubmitPrizePool(handleSavePrizePool)}>
                        <Box className='inbetween-field'>
                            <Box className='pool-range-field'>
                                <label><FormattedMessage id="prize.pool.amount" />:</label>
                                <Controller
                                    control={controlPrizePool}
                                    name="prizePoolAmount"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            InputProps={{ style: { height: "2rem" } }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className='pool-range-field'>
                                <label><FormattedMessage id="jackpot.expenses" />:</label>
                                <Controller
                                    control={controlPrizePool}
                                    name="jackpotExpenses"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            InputProps={{ style: { height: "2rem" } }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box className='savebuttoncomponent' >
                            <Button
                                variant="contained"
                                className="save-button"
                                type="submit"
                            >
                                <FormattedMessage id="save" />
                            </Button>
                        </Box>
                    </form>
                </Box>

                {/* second 6d number form */}
                <Box className='default-section'>
                    <Box borderBottom="1px solid #e3e4e6" style={{ width: "100%" }}>
                        <Typography className="section-title">
                            <FormattedMessage id="sixd.result" />
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmitSixdResultDate(handleSaveSixdResultDate)}>
                        <Box className='sixd-datefield'>
                            <Box className='sixd-component'>
                                <label className='calendar-label'><FormattedMessage id="select.date" />:</label>
                                <Controller
                                    control={controlSixdResultDate}
                                    name="selectedSixdResultDate"
                                    defaultValue={selectedSixdResultDate}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <DatePicker
                                            onChange={(date) => {
                                                const dateString = moment(date).format("YYYY-MM-DD")
                                                onChange(dateString)
                                                setSelectedSixdResultDate(dateString); // update selected date
                                            }}
                                            onBlur={onBlur}
                                            selected={value ? new Date(value) : null}
                                            showTimeSelect={false}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<CustomInput onClear={handleSixdResultDate} />}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className='sixd-component'>
                                <label className="transparent"> cheat </label>
                                <Button type="submit" className="go-button" variant='contained'><FormattedMessage id="go" /></Button>
                            </Box>
                        </Box>
                    </form>

                    <form onSubmit={handleSubmitSixdResult(handleSaveSixdResult)}>
                        <Box className='component'>
                            <Box className='sixd-resultfield'>
                                <label><FormattedMessage id="enter.sixd.result" />:</label>
                                <Controller
                                    control={controlSixdResult}
                                    name="SixdResult"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            InputProps={{ style: { height: "2rem" } }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box className='savebuttoncomponent'>
                            <Button
                                variant="contained"
                                className="save-button"
                                type="submit"
                            >
                                <FormattedMessage id="save" />
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>

            {openPrizePoolDateModal && (
                <NewUserModal
                    message={status === 'success' ? prizePoolDateSuccessMessage : prizePoolDateFailedMessage}
                    onConfirm={handleOK}
                    onClose={handleOK}
                />
            )}

            {opensixDdate && (
                <NewUserModal
                    message={status === 'success' ? sixDdateSuccessMessage : sixDdateFailedMessage}
                    onConfirm={handleOK}
                    onClose={handleOK}
                />
            )}

            {opensixDField && (
                <NewUserModal
                    message={status === 'success' ? sixDFieldSuccessMessage : sixDFieldFailedMessage}
                    onConfirm={handleOK}
                    onClose={handleOK}
                />
            )}
        </Box>
    )
}

export default Settings