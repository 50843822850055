import axios from '../../utils/helper'
import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, FormLabel, TextField, Grid, Modal, Typography, IconButton, TableContainer } from "@mui/material";
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid"
import DatePicker from 'react-datepicker'
import Header from "components/Header";
import Button from '@mui/material/Button';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import '../../css/receipt.css'
import '../../css/global.css'
import { FormattedMessage } from "react-intl";
import { useAuthContext } from "hooks/useAuthContext";
import moment from "moment";
import '../../css/searchDialog.css'

import CloseIcon from '@mui/icons-material/Close';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const AllReceipt = () => {
    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")
    const dataGridWidth = useMediaQuery("(max-width: 1200px)")

    const { user } = useAuthContext()

    // === Data Grid ===
    const [pageState, setPageState] = useState({
        isLoading: false,
        receipts: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const receiptColumn = [
        {
            field: 'rowIndex',
            headerName: 'No',
            minWidth: dataGridWidth ? 80 : 140,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'action',
            headerName: <FormattedMessage id="action" />,
            minWidth: dataGridWidth ? 170 : 180,
            headerAlign: "center",
            align: "center",
            editable: false,
            renderCell: (params) => (
                <Button
                    onClick={() => handleNumberSearch(params.row.receiptID)}
                >
                    <FormattedMessage id='detail.button' />
                </Button>
            ),
        },
        {
            field: 'luckyAmount',
            headerName: <FormattedMessage id="total" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            minWidth: dataGridWidth ? 150 : 230,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'playerID',
            headerName: <FormattedMessage id="username" />,
            minWidth: dataGridWidth ? 150 : 230,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'receiptID',
            headerName: <FormattedMessage id="id" />,
            minWidth: dataGridWidth ? 140 : 260,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'drawDate',
            headerName: <FormattedMessage id="draw.date" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY"),
            minWidth: dataGridWidth ? 190 : 230,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: <FormattedMessage id="bet.date" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY h:mma"),
            minWidth: dataGridWidth ? 250 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        // {
        //     field: 'importBy',
        //     headerName: <FormattedMessage id="agent.platform" />,
        //     minWidth: dataGridWidth ? 190 : 230,
        //     headerAlign: "center",
        //     align: "center",
        //     editable: false,
        // },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // === Input Fields ===
    const [fields, setFields] = useState({
        playerID: '',
        receiptID: '',
        luckyNum: '',
        selectedDrawDate: null,
        selectedBetDate: null
    })

    // === Only Page and Limit ===
    const fetchPageReceipts = async () => {
        try {

            const drawDate = fields.selectedDrawDate ? moment(fields.selectedDrawDate).format('YYYY-MM-DD') : null
            const createdAt = fields.selectedBetDate ? moment(fields.selectedBetDate).format('YYYY-MM-DD') : null

            const { data } = await axios.get(`/api/receipt?createdAt=${createdAt}&drawDate=${drawDate}&luckyNum=${fields.luckyNum}&playerID=${fields.playerID}&receiptID=${fields.receiptID}&page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            const receiptWithIndex = data.receiptsWithImportBy.map((receipt, index) => ({
                ...receipt,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, receipts: receiptWithIndex, total: data.pageTotal }))

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchPageReceipts()
    }, [pageState.page, pageState.pageSize])

    // === DatePicker ===
    function handleTodayClick() {
        const currentDate = moment().toDate();
        setFields({ ...fields, selectedBetDate: currentDate });
    }

    function handleYesterdayClick() {
        const yesterdayDate = moment().subtract(1, 'day').toDate();
        setFields({ ...fields, selectedBetDate: yesterdayDate });
    }

    const handleClearDrawDate = () => {
        setFields({ ...fields, selectedDrawDate: null });
    };

    const handleClearBetDate = () => {
        setFields({ ...fields, selectedBetDate: null });

    };

    const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '0.5px solid #A5CDF7',
                borderRadius: '0.3rem',
                width: '13rem',
                height: '2rem',
                padding: '0.2rem 0.5rem',
                fontSize: '13px',
                color: '#474747',
                fontWeight: 500,
                alignItems: 'center',
            }}
            onClick={onClick}
        >
            <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#2A75EA', marginRight: '4px' }} />
            <span style={{}}>{value}</span>
            {value && (
                <ClearIcon
                    sx={{ fontSize: '20px', color: '#999', cursor: 'pointer' }}
                    onClick={onClear}
                />
            )}
        </Box>
    ));

    // for the action button
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)

    const handleNumberSearch = async (receiptID) => {
        try {
            const { data } = await axios.get(`/api/receipt/search?value=${receiptID}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            setOpen(true)
            setData(data)
        } catch (error) {
            console.error(error)
        }
    }

    // for search result dialog box
    const handleClose = () => {
        setOpen(false)
        setData(null)
    }

    const handleSearch = async () => {
        try {
            const drawDate = fields.selectedDrawDate ? moment(fields.selectedDrawDate).format('YYYY-MM-DD') : null
            const createdAt = fields.selectedBetDate ? moment(fields.selectedBetDate).format('YYYY-MM-DD') : null

            const { data } = await axios.get(`/api/receipt?createdAt=${createdAt}&drawDate=${drawDate}&luckyNum=${fields.luckyNum}&playerID=${fields.playerID}&receiptID=${fields.receiptID}`,
                {
                    headers: { 'Authorization': `Bearer ${user.accessToken}` }
                })

            const receiptWithIndex = data.receiptsWithImportBy.map((receipt, index) => ({
                ...receipt,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, receipts: receiptWithIndex, total: data.pageTotal, page: 1, pageSize: 50 }))

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="receipt" />} />

            <Box
                mt="5px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="2rem"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >
                    <Box className="form" sx={{ paddingBottom: "2rem" }}>

                        <Box className="details-component">
                            <Box className="input-field">
                                <FormLabel className="field-label"><FormattedMessage id="username" />: </FormLabel>
                                <TextField
                                    InputProps={{ style: { height: "2rem" } }}
                                    value={fields.playerID}
                                    onChange={(e) => setFields({ ...fields, playerID: e.target.value })}
                                />
                            </Box>
                            <Box className="input-field">
                                <FormLabel className="field-label"><FormattedMessage id="id" />:</FormLabel>
                                <TextField
                                    InputProps={{ style: { height: "2rem" } }}
                                    value={fields.receiptID}
                                    onChange={(e) => setFields({ ...fields, receiptID: e.target.value })}
                                />
                            </Box>
                            <Box className="input-field">
                                <FormLabel className="field-label"><FormattedMessage id="number" />:</FormLabel>
                                <TextField
                                    InputProps={{ style: { height: "2rem" } }}
                                    value={fields.luckyNum}
                                    onChange={(e) => setFields({ ...fields, luckyNum: e.target.value })}
                                />
                            </Box>
                        </Box>

                        <Box className="details-component">
                            <Box className="input-field">
                                <FormLabel className="field-label" sx={{ width: '100%' }}><FormattedMessage id="draw.date" />:</FormLabel>
                                <DatePicker
                                    selected={fields.selectedDrawDate}
                                    onChange={date => setFields({ ...fields, selectedDrawDate: date })}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomInput onClear={handleClearDrawDate} />}
                                />
                            </Box>
                            <Box className="input-field">
                                <FormLabel className="field-label" sx={{ width: '100%' }}><FormattedMessage id="bet.date" />:</FormLabel>
                                <DatePicker
                                    selected={fields.selectedBetDate}
                                    onChange={date => setFields({ ...fields, selectedBetDate: date })}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomInput onClear={handleClearBetDate} />}
                                />
                            </Box>
                        </Box>

                        <Box className="details-component">
                            <Box className="input-field">
                                {/* <label className="search-transparent">To: </label> */}

                                <Grid container className="grid-button" gap="5px">
                                    <Grid item>
                                        <Button className="search-button" variant="contained" onClick={handleSearch}><FormattedMessage id="search" /></Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className="filter-button" variant="contained" onClick={handleYesterdayClick} startIcon={<CalendarMonthOutlinedIcon />}><FormattedMessage id="yesterday" /></Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className="filter-button" variant="contained" onClick={handleTodayClick} startIcon={<CalendarMonthOutlinedIcon />}><FormattedMessage id="today" /></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        height="57vh"
                    >

                        <DataGrid
                            columns={receiptColumn}
                            rows={pageState.receipts}
                            getRowId={(row) => row._id}
                            paginationMode='server'
                            loading={pageState.isLoading}
                            rowCount={pageState.total}
                            initialState={{ pagination: { paginationModel: { pageSize: pageState.pageSize } } }}
                            pageSizeOptions={[50]}
                            onPaginationModelChange={(pageState) => {
                                setPageState((old) => ({ ...old, page: pageState.page + 1, pageSize: pageState.pageSize }))
                            }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                border: 1,
                                borderColor: 'primary.light',
                                textAlign: 'left',
                                justifyContent: 'left',
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#f7f3e9',
                                },
                                [`& .${gridClasses.row}.even`]: {
                                    backgroundColor: '#2A75EA1C'
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    borderBottom: '1px solid #2A75EA',
                                    // width: '100%'
                                },
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            <Box>
                <Modal open={open} onClose={handleClose}>
                    {data ? (
                        <Box className="search-dialog-box">
                            <Box className="searchBox-header">
                                <Box flexGrow={1} paddingLeft="40px" paddingBottom="15px" paddingTop="15px">
                                    <Typography color='green'><FormattedMessage id='betID' />: {data.playerID}</Typography>
                                </Box>
                                <IconButton>
                                    <CloseIcon onClick={handleClose} />
                                </IconButton>
                            </Box>
                            <Box className='search-details'>

                                {/* table here */}
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='receiptID' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data._id}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='dateTime' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{new Date(data.createdAt).getDate()}-{new Date(data.createdAt).toLocaleString('default', { month: 'short' })}-{new Date(data.createdAt).getFullYear()}, {new Date(data.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='agent' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.importBy}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='betID' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.playerID}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='betUnit' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.luckyUnit}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='betAmount' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.luckyAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='number' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='searchValueContainer'>
                                                        <Typography className='searchValue' >
                                                            {data.luckyNum.map((number) => (<div key={number}>{number}</div>))}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            {/* <Divider /> */}

                            <Box className="ok-box">
                                <Button variant="contained" className="ok-button" onClick={handleClose}>
                                    ok
                                </Button>
                            </Box>

                        </Box>
                    ) : (
                        <Typography variant="h5"><FormattedMessage id='no.record' /></Typography>
                    )}
                </Modal>
            </Box>

        </Box>
    )
}

export default AllReceipt

