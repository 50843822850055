import useAuth from 'hooks/useAuth';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import FlexBetween from 'components/FlexBetween';
import Logo from '../../assets/gmail_logo.png'
import receiptIcon from "../../assets/receipt.png"
import settingIcon from "../../assets/setting.png"
import dashboardIcon from "../../assets/dashboard.png"
import userIcon from "../../assets/user-management.png"
import winlossIcon from "../../assets/win-loss-report.png"
import numberIcon from '../../assets/number.png'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';


// list of navitem
const navItems = [
    {
        text: "Dashboard",
        translationKey: "dashboardNav",
        image: <img src={dashboardIcon} alt="dashboard" width="30" height="30" />
    },
    {
        text: "Receipt",
        translationKey: "receiptNav",
        image: <img src={receiptIcon} alt="receipt" width="30" height="30" />
    },
    {
        // text: "Win/Loss Report",
        text: "Report",
        translationKey: "winLossReportNav",
        image: <img src={winlossIcon} alt="report" width="30" height="30" />
    },
    {
        text: "Schedule",
        translationKey: "scheduleNav",
        image: <img src={numberIcon} alt="numbers" width="30" height="30" />
    },
    {
        text: "User Management",
        translationKey: "userManagementNav",
        image: <img src={userIcon} alt="user" width="30" height="30" />
    },
    {
        text: "Settings",
        translationKey: "settingsNav",
        image: <img src={settingIcon} alt="setting" width="30" height="30" />
    }
]

const Sidebar = ({
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
}) => {
    // what page we are currently at
    const sidebarRef = useRef(null);
    const { pathname } = useLocation()
    const [active, setActive] = useState("")        // keep track url, determine which page we are on

    const navigate = useNavigate()
    const theme = useTheme()

    // const { isAdmin } = useAuth()

    // const filteredNavItems = navItems.filter(item => isAdmin || (item.text !== "User Management" && item.text !== "Settings"));

    const { role } = useAuth();

    const filteredNavItems = navItems.filter(item => role === 'admin' || (item.text !== 'User Management' && item.text !== 'Settings'));



    useEffect(() => {
        setActive(pathname.substring(1))        // when change pathname, set the active value to the current page
    }, [pathname]);

    // const [isSidebarOpen, setIsSidebarOpen] = useState(!isNonMobile);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!isNonMobile && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isNonMobile, setIsSidebarOpen]);

    // Check if isNonMobile is false and set the sidebar open by default
    useEffect(() => {
        if (isNonMobile) {
            setIsSidebarOpen(true);
        }
    }, [isNonMobile, setIsSidebarOpen]);

    const handleOpenClose = () => {
        if (!isNonMobile) {
            setIsSidebarOpen(false)
        }
    }

    const DrawerContent = () => {

        return (
            <Box>
                {/* Nav Item */}
                <List>
                    {filteredNavItems.map(({ text, image, translationKey }) => {
                        if (!image) {
                            return (
                                <Typography key={text}>
                                    {text}
                                </Typography>
                            )
                        }

                        const lcText = text.toLowerCase().replace(/\s+/g, '') === "win/loss report" ? "report" : text.toLowerCase().replace(/\s+/g, '')

                        return (
                            <ListItem key={text} disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        navigate(`/${lcText}`);
                                        setActive(lcText)
                                    }}
                                    sx={{
                                        fontSize: '14px',
                                        backgroundColor: active === lcText ? "#E6EBF8" : "transparent",
                                        color: active === lcText ? 'black' : "#747474",
                                        borderRadius: active === lcText ? '0.55rem' : 'none',
                                        margin: "0.3rem 1rem"
                                    }}
                                >
                                    {image}
                                    <ListItemText primary={<FormattedMessage id={translationKey} />} sx={{ marginLeft: '1rem' }} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
        )
    }

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    onClick={handleOpenClose}
                    variant="permanent"
                    anchor='left'
                    ref={sidebarRef}
                    sx={{
                        width: drawerWidth,
                        "& .MuiDrawer-paper": {
                            color: theme.palette.secondary[200],
                            backgroundColor: "#FFFFFF",
                            boxSizing: "border-box",
                            borderWidth: isNonMobile ? 0 : "2px",
                            width: drawerWidth,
                            flexShrink: 0,
                        }
                    }}
                >
                    <Box width="100%">
                        <Box m="1.5rem 2rem 1rem 2rem" >
                            <Box color="#4385EA">

                                {!isNonMobile && (
                                    <FlexBetween gap="0.5rem">
                                        <Typography>
                                            <img src={Logo} alt="Logo" style={{ width: "30px", height: "25px" }} onClick={() => { navigate(`/dashboard`) }} />
                                        </Typography>

                                        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                            <ChevronLeftOutlinedIcon />
                                        </IconButton>
                                    </FlexBetween>
                                )}

                                {isNonMobile && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'end'
                                        }}
                                    >
                                        <Typography>
                                            <img src={Logo} alt="Logo" style={{ width: "30px", height: "25px" }} onClick={() => { navigate(`/dashboard`) }} />
                                        </Typography>

                                        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                            <ChevronLeftOutlinedIcon />
                                        </IconButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <DrawerContent />

                    </Box>
                </Drawer>
            )}
        </Box>
    )
}

export default Sidebar