import jwtDecode from "jwt-decode";

// const useAuth = () => {
//     const token = JSON.parse(localStorage.getItem('user')).accessToken;
//     let isAdmin = false

//     if (token) {
//         const decoded = jwtDecode(token)

//         const { username, isAdmin } = decoded.UserInfo

//         return { username, isAdmin }
//     }
//     return { username: '', isAdmin }
// }

// export default useAuth

// const useAuth = () => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     let isAdmin = false;
//     let isSub = false;

//     if (user && user.accessToken) {
//         const decoded = jwtDecode(user.accessToken);
//         const { username, isAdmin: userIsAdmin, isSub: userIsSub } = decoded.UserInfo;
//         isAdmin = userIsAdmin;
//         isSub = userIsSub;
//         return { username, isAdmin, isSub };
//     }

//     return { username: '', isAdmin, isSub };
// };

const useAuth = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let role = '';

    if (user && user.accessToken) {
        const decoded = jwtDecode(user.accessToken);
        const { username, role: userRole } = decoded.UserInfo;
        role = userRole || '';
        return { username, role };
    }

    return { username: '', role };
};

export default useAuth;
