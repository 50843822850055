import { Box, useMediaQuery, TextField, Button } from "@mui/material"
import Header from "components/Header"
import '../../css/user.css';
import '../../css/global.css'
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from 'react-hook-form'
import { useAuthContext } from "hooks/useAuthContext";
import axios from '../../utils/helper'
import { useState } from "react";
import NewUserModal from "components/CreateUserModal";

const CreateNewUser = () => {
    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")
    const { user } = useAuthContext()

    const [status, setStatus] = useState(null)

    const intl = useIntl();
    const successMessage = intl.formatMessage({ id: 'success' });
    const failedMessage = intl.formatMessage({ id: 'fail' });

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleOK = () => {
        setOpenModal(false);
    };

    const { control: userControl, handleSubmit: handleSubmitUser, formState: { errors } } = useForm()

    const validateUsername = (value) => {
        const regex = /^[A-Za-z0-9]+$/;
        return regex.test(value) || intl.formatMessage({ id: 'createUser.nameError' });
    };

    const handleSaveNewUser = async (data) => {
        try {
            const response = await axios.post('/api/user/createAgent', {
                username: data.username,
                password: data.password
            }, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            });

            setStatus('success');
            handleOpenModal('success');

        } catch (error) {
            setStatus('error');
            handleOpenModal('error');
        }
    };

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="create.new.user" />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="2rem"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >
                    <form onSubmit={handleSubmitUser(handleSaveNewUser)}>
                        <Box paddingBottom={1}>
                            <label><FormattedMessage id='new.agent.username' />:</label>
                        </Box>
                        <Box>
                            <Controller
                                control={userControl}
                                name="username"
                                defaultValue=""
                                rules={{ validate: validateUsername }}
                                render={({ field }) => (
                                    <TextField
                                        className="textField"
                                        {...field}
                                        InputProps={{ style: { height: "2rem" } }}
                                        error={!!errors.username}
                                        helperText={errors.username?.message}
                                    />
                                )}
                            />
                        </Box>
                        <Box paddingTop={3} paddingBottom={1}>
                            <label><FormattedMessage id='new.agent.password' />:</label>
                        </Box>
                        <Box>
                            <Controller
                                control={userControl}
                                name="password"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        className="textField"
                                        {...field}
                                        InputProps={{ style: { height: "2rem" } }}
                                    />
                                )}
                            />
                        </Box>
                        <Box paddingTop={4}>
                            <Button
                                variant="contained"
                                className="save-button"
                                type="submit"
                            >
                                <FormattedMessage id="save" />
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>

            {openModal && (
                <NewUserModal
                    message={status === 'success' ? successMessage : failedMessage}
                    onConfirm={handleOK}
                    onClose={handleOK}
                />
            )}
        </Box>
    )
}

export default CreateNewUser