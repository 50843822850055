import { useState, useEffect } from "react";
import { Box, useMediaQuery, Grid, FormLabel, TextField } from "@mui/material"
import Button from '@mui/material/Button';
import Header from "components/Header"
import { styled } from "@mui/system";
// import { makeStyles } from '@mui/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TickIcon from '../../assets/tick.png'
import CloseIcon from '../../assets/close.png'
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import "../../css/number.css"
import '../../css/global.css'
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useAuthContext } from "hooks/useAuthContext";
import axios from "axios";


// from here

const columnsSold = [
    {
        field: 'rowIndex',
        headerName: 'No',
        flex: 1,
        minWidth: 90,
    },
    {
        field: '_id',
        headerName: <FormattedMessage id="id" />,
        flex: 1,
        minWidth: 90,
    },
    // {
    //     field: 'receiptID',
    //     headerName: <FormattedMessage id="id" />,
    //     flex: 2,
    //     minWidth: 120,
    //     editable: false,
    // },
    {
        field: 'numbers',
        headerName: <FormattedMessage id="number" />,
        flex: 2,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'agent',
        headerName: <FormattedMessage id="agent.platform" />,
        flex: 2,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'playerUsername',
        headerName: <FormattedMessage id="username" />,
        flex: 2,
        minWidth: 150,
        editable: false,
    },
    {
        field: 'betDate',
        headerName: <FormattedMessage id="bet.date" />,
        flex: 2,
        minWidth: 180,
    },
];

const columnsUnsold = [
    {
        field: 'id',
        headerName: 'No',
        flex: 1,
        minWidth: 90,
    },
    {
        field: 'numbers',
        headerName: <FormattedMessage id="number" />,
        flex: 2,
        minWidth: 120,
        editable: false,
    }
];

const rowsUnsold = [
    { id: 1, numbers: '100100' },
    { id: 2, numbers: '156489' }
];

const MyTabs = styled(Tabs)({
    height: "4rem",
    "& .MuiTabs-scroller": {
        borderBottom: "none",
    }
});

const MyTab = styled(Tab)(({ theme }) => ({
    backgroundColor: "#2A75EA1C",
    textTransform: "none",
    fontWeight: "500",
    fontSize: "13px",
    borderRadius: "0.5rem",
    display: "flex",
    width: "50%",
    boxShadow: '1px 2px 5px #cccccc',
    "&.Mui-selected": {
        backgroundColor: "#2A75EA",
        color: "white",
        borderBottom: "none",
    },
    "& .MyTab-label": {
        display: "flex",
        alignItems: "center"
    },
    "& .MyTab-icon": {
        marginRight: theme.spacing(1)
    }
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <div sx={{ p: 3 }}>
                    {children}
                </div>
            )}
        </div>
    );
}

const CustomFormField = ({ name, control, defaultValue }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
                <TextField
                    {...field}
                    InputProps={{ style: { height: '2rem' } }}
                />
            )}
        />
    )
}

const Numbers = () => {
    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")

    const [value, setValue] = useState(0);
    const [status, setStatus] = useState(false)

    const [soldNum, setSoldNum] = useState([])
    const [unsoldNum, setUnsoldNum] = useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setStatus(newValue === 1);
    };

    const { control: controlSoldNumber, handleSubmit: handleSubmitSoldNumber } = useForm()
    const { control: controlUnSoldNumber, handleSubmit: handleSubmitUnSoldNumber } = useForm()

    const handleSearchSoldNumber = async (data) => {
        const luckyNum = data.sold6dNumber
        const playerID = data.soldBetID

        try {
            const { data } = await axios.get(`/number?status=${status}&luckyNum=${luckyNum}&playerID=${playerID}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })
            console.log("data is")
            console.table(data)
            const numbersWithIDS = data.map((number, index) => ({
                ...number,
                rowIndex: index + 1
            }))
            setSoldNum(numbersWithIDS)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearchUnsoldNumber = (data) => {
        console.log(data.unsold6dNumber)
    }

    const { user } = useAuthContext()

    // Sold Page State
    const [soldPageState, setSoldPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })

    // Sold Page State
    const [unsoldPageState, setUnsoldPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    })

    // Sold Page useEffect
    // useEffect(() => {
    //     const fetchNumber = async () => {
    //         try {
    //             const { data } = await axios.get(`/number?status=${status}`, {
    //                 headers: { 'Authorization': `Bearer ${user.accessToken}` }
    //             })
    //             console.log("data is")
    //             console.table(data.result)
    //             // const numbersWithIDS = data.result.map((number, index) => ({
    //             //     ...number,
    //             //     rowIndex: index + 1
    //             // }))
    //             // setSoldPageState({
    //             //     data: numbersWithIDS,
    //             //     total: data.total
    //             // })
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    //     fetchNumber()
    // }, [status])


    // Unsold Page useEffect
    // useEffect(() => {
    //     const fetchNumber = async () => {
    //         try {
    //             const { data } = await axios.get(`/number?status=${status}`, {
    //                 headers: { 'Authorization': `Bearer ${user.accessToken}` }
    //             })
    //             console.log("data is")
    //             console.table(data.result)
    //             // const numbersWithIDS = data.result.map((number, index) => ({
    //             //     ...number,
    //             //     rowIndex: index + 1
    //             // }))
    //             // setSoldPageState({
    //             //     data: numbersWithIDS,
    //             //     total: data.total
    //             // })
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    //     fetchNumber()
    // }, [])

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="numbers" />} />

            <Box
                gridColumn="span 12"
                height="fit-content"
                borderRadius="0.55rem"
                mt="15px"
            >
                <MyTabs indicatorColor={""} value={value} onChange={handleChange}>
                    <MyTab label=
                        {<div className="MyTab-label">
                            <img src={TickIcon} alt="Icon" className="MyTab-icon" width="20px" height="20px" />
                            <FormattedMessage id='sold.numbers' />
                        </div>}
                    />
                    <MyTab label=
                        {<div className="MyTab-label">
                            <img src={CloseIcon} alt="Icon" className="MyTab-icon" width="20px" height="20px" />
                            <FormattedMessage id='unsold.numbers' />
                        </div>}
                    />
                </MyTabs>
            </Box>

            <Box
                mt="5px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="2rem"
                    borderRadius="0.55rem"
                    sx={{ boxShadow: '1px 2px 5px #cccccc' }}
                >

                    <TabPanel value={value} index={0} className="tab-content">
                        {/* <form onSubmit={handleSubmitSoldNumber(handleSearchSoldNumber)}>
                            <Box pb="2rem">
                                <Box className="details-component">
                                    <Box className="input-field">
                                        <FormLabel className="field-label"><FormattedMessage id="number" />:</FormLabel>
                                        <CustomFormField
                                            control={controlSoldNumber}
                                            name="sold6dNumber"
                                            defaultValue=""
                                        />
                                    </Box>
                                    <Box className="input-field">
                                        <FormLabel className="field-label"><FormattedMessage id="id" />:</FormLabel>
                                        <CustomFormField
                                            control={controlSoldNumber}
                                            name="soldReceiptID"
                                            defaultValue=""
                                        />
                                    </Box>
                                    <Box className="input-field">
                                        <FormLabel className="field-label"><FormattedMessage id="username" />:</FormLabel>
                                        <CustomFormField
                                            control={controlSoldNumber}
                                            name="soldBetID"
                                            defaultValue=""
                                        />
                                    </Box>
                                    <Box className="input-field">
                                        <Button type="submit" className="search-button" variant="contained" width="100%"><FormattedMessage id="search" /></Button>
                                    </Box>

                                </Box>
                            </Box>
                        </form> */}

                        {/* TESTING */}
                        <DataGrid
                            rows={soldNum}
                            columns={columnsSold}
                            // {...data}
                            // rowCount={rowCountState}
                            loading={soldPageState.isLoading}
                            pageSizeOptions={[10, 50, 100]}
                            // paginationModel={paginationModel}
                            paginationMode="server"
                        // onPaginationModelChange={setPaginationModel}
                        />

                        {/* Sold Numbers */}
                        {/* <DataGrid
                            // rows={soldPageState.data}
                            // rowCount={soldPageState.total}
                            // loading={soldPageState.isLoading}
                            columns={columnsSold}
                            rows={soldNum}
                            getRowId={(row) => row._id}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 50 } },
                            }}
                            pageSizeOptions={[50]}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                border: 1,
                                borderColor: 'primary.light',
                                textAlign: 'left',
                                justifyContent: 'left',
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#f7f3e9',
                                },
                                [`& .${gridClasses.row}.even`]: {
                                    backgroundColor: '#2A75EA1C'
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    borderBottom: '1px solid #2A75EA'
                                },
                            }}
                            slots={{ toolbar: CustomToolbar }}
                        /> */}
                    </TabPanel>

                    <TabPanel value={value} index={1} className="tab-content">

                        {/* <form onSubmit={handleSubmitUnSoldNumber(handleSearchUnsoldNumber)}>
                            <Box pb="2rem">
                                <Box className="details-component">
                                    <Box className="input-field">
                                        <FormLabel className="field-label"><FormattedMessage id="number" />:</FormLabel>
                                        <Controller
                                            control={controlUnSoldNumber}
                                            name="unsold6dNumber"
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    InputProps={{ style: { height: "2rem" } }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box className="input-field">
                                        <Button type="submit" className="search-button" variant="contained" width="100%"><FormattedMessage id="search" /></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </form> */}

                        {/* Unsold Numbers */}
                        <DataGrid
                            rows={unsoldPageState.data}
                            rowCount={unsoldPageState.total}
                            loading={unsoldPageState.isLoading}
                            getRowId={(row) => row._id}
                            rowsPerPageOptions={[25, 50, 100]}
                            columns={columnsUnsold}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                border: 1,
                                borderColor: 'primary.light',
                                textAlign: 'left',
                                justifyContent: 'left',
                                "& .MuiDataGrid-row": {
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#f7f3e9',
                                },
                                [`& .${gridClasses.row}.even`]: {
                                    backgroundColor: '#2A75EA1C'
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    borderBottom: '1px solid #2A75EA'
                                },
                            }}
                            slots={{ toolbar: CustomToolbar }}
                        />
                    </TabPanel>

                </Box>
            </Box>
        </Box>
    )
}

export default Numbers