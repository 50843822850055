import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import '../../css/changePassword.css';
import { FormattedMessage } from "react-intl";

const ChangePasswordSuccess = () => {

    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")
    const isLaptop = useMediaQuery("(min-width: 768px)")

    const Navigate = useNavigate()

    const handleClick = () => {
        Navigate('/changepassword')
    }
    const handleGoHome = () => {
        Navigate('/')
    }

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="change.password" />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    backgroundColor="#FFFFFF"
                    height="fit-content"
                    p="2rem"
                    borderRadius="0.55rem"
                >

                    <Typography sx={{ paddingBottom: "15px" }}>
                        <FormattedMessage id='success.message' />
                    </Typography>

                    <Box display='flex' gap="10px">
                        <Button
                            variant="contained"
                            className="save-button"
                            onClick={handleClick}
                        >
                            OK
                        </Button>

                        <Button
                            variant="contained"
                            className="save-button"
                            onClick={handleGoHome}
                        >
                            <FormattedMessage id="go.to.home" />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default ChangePasswordSuccess