import axios from '../../utils/helper'
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { Box, useMediaQuery, FormLabel, Button, Modal, TableCell, Typography, TableRow, TableBody, TableContainer, Table, IconButton, Grid } from "@mui/material"
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid"
import { FormattedMessage } from "react-intl"
import Header from "components/Header"
import DatePicker from 'react-datepicker'
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import '../../css/report.css'
import CloseIcon from '@mui/icons-material/Close';

const WinLossReport = () => {

    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")
    const { user } = useAuthContext()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const getRowId = (row) => row._id

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    const handleCheckStrike = async (id) => {
        try {
            const { data } = await axios.get(`/api/report/strike?id=${id}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            setOpen(true)
            setData(data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setData(null)
    }


    const columnsSuperadminWinLoss = [
        {
            field: 'rowIndex',
            headerName: 'No',
            flex: 1,
            minWidth: 90,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'drawDate',
            headerName: <FormattedMessage id="draw.date" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY"),
            flex: 2,
            minWidth: 120,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'totalUnits',
            headerName: <FormattedMessage id="total.bet.unit" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined) : '',
            flex: 2,
            minWidth: 160,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'sales',
            headerName: <FormattedMessage id="total.bet.rm" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            flex: 2,
            minWidth: 160,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'commissionExpense',
            headerName: <FormattedMessage id="commission.expenses.rm" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            flex: 2,
            minWidth: 230,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'jackpotExpense',
            headerName: <FormattedMessage id="jackpot.expenses.rm" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            flex: 2,
            minWidth: 210,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'strike',
            headerName: <FormattedMessage id="total.strike" />,
            renderCell: (params) => (
                <div
                    style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                    onClick={() => handleCheckStrike(params.row._id)}
                >
                    {params.value ? params.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }) : ''}
                </div>
            ),
            flex: 2,
            minWidth: 120,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'profit',
            headerName: <FormattedMessage id="total.balance.rm" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            cellClassName: (params) => params.value >= 0 ? 'positive-value' : 'negative-value',
            flex: 2,
            minWidth: 140,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
    ];

    const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '0.5px solid #A5CDF7',
                borderRadius: '0.3rem',
                width: '13rem',
                height: '2rem',
                padding: '0.2rem 0.5rem',
                fontSize: '13px',
                color: '#474747',
                fontWeight: 500,
                alignItems: 'center',
            }}
            onClick={onClick}
        >
            <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#2A75EA', marginRight: '4px' }} />
            <span style={{}}>{value}</span>
            {value && (
                <ClearIcon
                    sx={{ fontSize: '20px', color: '#999', cursor: 'pointer' }}
                    onClick={onClear}
                />
            )}
        </Box>
    ));

    const handleStartDate = () => {
        setStartDate(null)
    }

    const handleEndDate = () => {
        setEndDate(null)
    }

    const calculateColumnSum = (rows, field) => {
        return rows.reduce((sum, row) => sum + (row[field] || 0), 0)
    };

    const CustomFooter = ({ rows }) => {
        if (!rows) {
            return null
        }

        const totalUnitsSum = calculateColumnSum(rows, 'totalUnits')
        const salesSum = calculateColumnSum(rows, 'sales')
        const commissionExpenseSum = calculateColumnSum(rows, 'commissionExpense')
        const jackpotExpenseSum = calculateColumnSum(rows, 'jackpotExpense')
        const strikeSum = calculateColumnSum(rows, 'strike')
        const profitSum = calculateColumnSum(rows, 'profit')

        return (
            <Box className='total-sum'>
                <Grid container spacing={1} columns={12} >
                    <Grid item xs={6} sm={6} md={4} xl={2} borderRight={{ xs: "1px solid #cdced1" }} borderBottom={{ xs: "1px solid #cdced1", xl: 'none' }}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='total.bet.unit' /></Typography>
                            <Typography className='sum-data'>{totalUnitsSum}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} xl={2} borderRight={{ sm: "1px solid #cdced1", sm: 'none', md: "1px solid #cdced1" }} borderBottom={{ xs: "1px solid #cdced1", xl: 'none' }}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='total.bet.rm' /></Typography>
                            <Typography className='sum-data'>{salesSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} xl={2} borderRight={{ xs: "1px solid #cdced1", md: 'none', xl: "1px solid #cdced1" }} borderBottom={{ xs: "1px solid #cdced1", xl: 'none' }}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='commission.expenses.rm' /></Typography>
                            <Typography className='sum-data'>{commissionExpenseSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} xl={2} borderRight={{ md: "1px solid #cdced1" }} borderBottom={{ xs: "1px solid #cdced1", md: 'none' }}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='jackpot.expenses.rm' /></Typography>
                            <Typography className='sum-data'>{jackpotExpenseSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} xl={2} borderRight={{ xs: "1px solid #cdced1" }}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='total.strike' /></Typography>
                            <Typography className='sum-data'>{strikeSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} xl={2}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='total.balance.rm' /></Typography>
                            <Typography className='sum-data'>{profitSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // Testing
    const [pageState, setPageState] = useState({
        isLoading: false,
        report: [],
        total: 0,
        page: 1,
        pageSize: 20
    })

    const handleReport = async () => {
        try {
            const formatStart = moment(startDate).format('YYYY-MM-DD')
            const formatEnd = moment(endDate).format('YYYY-MM-DD')

            const { data } = await axios.get(`/api/report?startDate=${formatStart}&endDate=${formatEnd}&page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            const reportWithIndex = data.reportWithImportBy.map((report, index) => ({
                ...report,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, report: reportWithIndex, total: data.pageTotal }))

        } catch (error) {
            console.log(error)
        }
    }

    // Only Rerun when the pagge
    useEffect(() => {
        handleReport()
    }, [pageState.page, pageState.pageSize])

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="win.loss.report" />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" },
                    '& .positive-value': {
                        color: 'green',
                    },
                    '& .negative-value': {
                        color: 'red',
                    },
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="2rem"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >
                    <Box pb="2rem">
                        <Box className="details-component">
                            <Box className="input-field">
                                <FormLabel className="field-label"><FormattedMessage id="from" /> </FormLabel>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomInput onClear={handleStartDate} />}
                                />
                            </Box>
                            <Box className="input-field">
                                <FormLabel className="field-label"><FormattedMessage id="to" /> </FormLabel>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomInput onClear={handleEndDate} />}
                                />
                            </Box>
                            <Box className="input-field">
                                <Button className="search-button" variant="contained" onClick={handleReport}><FormattedMessage id="search" /></Button>
                            </Box>
                        </Box>
                    </Box>

                    <Box>

                        {pageState.report && pageState.report.length > 0 ? <CustomFooter rows={pageState.report} /> : null}
                    </Box>

                    <Box sx={{ paddingTop: '20px' }}>
                        <DataGrid
                            columns={columnsSuperadminWinLoss}
                            rows={pageState.report}
                            getRowId={getRowId}
                            paginationMode='server'
                            loading={pageState.isLoading}
                            rowCount={pageState.total}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: pageState.pageSize,
                                    },
                                },
                            }}
                            onPaginationModelChange={(pageState) => {
                                setPageState((old) => ({ ...old, page: pageState.page + 1, pageSize: pageState.pageSize }))
                            }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                border: 1,
                                borderColor: 'primary.light',
                                textAlign: 'left',
                                justifyContent: 'left',
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#f7f3e9',
                                },
                                [`& .${gridClasses.row}.even`]: {
                                    backgroundColor: '#2A75EA1C'
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    borderBottom: '1px solid #2A75EA'
                                },
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                                // footer: CustomFooter,
                            }}
                        />
                    </Box>


                </Box>
            </Box>

            <Box>
                <Modal open={open} onClose={handleClose}>
                    {data ? (
                        <Box className="search-dialog-box">
                            <Box className="searchBox-header">
                                <Box flexGrow={1} paddingLeft="40px" paddingBottom="15px" paddingTop="15px">
                                    <Typography color='green'><FormattedMessage id='betID' />: {data.playerID}</Typography>
                                </Box>
                                <IconButton>
                                    <CloseIcon onClick={handleClose} />
                                </IconButton>
                            </Box>
                            <Box className='search-details'>

                                {/* strike details */}
                                <TableContainer className='strike-table'>
                                    <Table>
                                        <TableBody>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='drawDate' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>
                                                        {new Date(data.drawDate).toLocaleDateString('en-US', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric'
                                                        })}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='drawNum' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.drawNum}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {/* table here */}
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='betID' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.playerID}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='receiptID' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.receiptID}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='dateTime' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>
                                                        {`${new Date(data.createdAt).getDate().toString().padStart(2, '0')}/${new Date(data.createdAt).toLocaleString('default', { month: '2-digit' }).padStart(2, '0')}/${new Date(data.createdAt).getFullYear()}, ${new Date(data.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}`}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='payout' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className='searchValue'>{data.payout.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className='searchTitle'><FormattedMessage id='6dnumber' /></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='searchValueContainer'>
                                                        <Typography className='searchValue' >
                                                            {data.luckyNum.map((number) => (<div key={number}>{number}</div>))}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            {/* <Divider /> */}

                            <Box className="ok-box">
                                <Button variant="contained" className="ok-button" onClick={handleClose}>
                                    ok
                                </Button>
                            </Box>

                        </Box>
                    ) : (
                        <Typography variant="h5"><FormattedMessage id='no.record' /></Typography>
                    )}
                </Modal>
            </Box>

        </Box>
    )
}

export default WinLossReport

