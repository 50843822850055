import * as yup from "yup";
import { useLogin } from "hooks/useLogin"
import { Box, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";

import axios from '../../utils/helper'

const Form = () => {

    const [captcha, setCaptcha] = useState({ svg: '', text: '' })

    const fetchCaptcha = async () => {
        try {
            const { data } = await axios.get(`/api/auth/captcha`);

            setCaptcha({ svg: data.captcha, text: data.text })
        } catch (error) {
            console.error('Error fetching captcha:', error);
        }
    };

    useEffect(() => {
        fetchCaptcha();
    }, []);

    const { login } = useLogin()
    const [error, setError] = useState(null)

    const handleFormSubmit = async (values) => {
        try {
            if (values.captcha === captcha.text) {
                await login(values)
            } else {
                setError('CAPTCHA verification failed');
            }
        } catch (error) {
            setError(error.message);
        }
    }

    const handleCloseErrorDialog = () => {
        setError(null)
    }

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesLogin}
            validationSchema={loginSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box className="login-field-component" >
                        <TextField
                            label={<FormattedMessage id="login.username" />}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={!!touched.username && !!errors.username}
                            helperText={touched.username && errors.username}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            label={<FormattedMessage id="login.password" />}
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={Boolean(touched.password) && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>

                    <Box className='captcha-component'>
                        <div dangerouslySetInnerHTML={{ __html: captcha.svg }} />

                        <TextField
                            label={<FormattedMessage id="login.captcha" />}
                            type="captcha"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.captcha}
                            name="captcha"
                            error={Boolean(touched.captcha) && Boolean(errors.captcha)}
                            helperText={touched.captcha && errors.captcha}
                            sx={{ gridColumn: "span 4" }}
                            // TEST
                            inputProps={{ maxLength: 4 }}
                        />
                    </Box>


                    <Box className="login-button-component">
                        <Button
                            type="submit"
                            className="login-field-button"
                        >
                            <FormattedMessage id="login" />
                        </Button>
                    </Box>

                    <Dialog open={Boolean(error)} onClose={handleCloseErrorDialog}>
                        <DialogTitle>
                            <FormattedMessage id="login.failed.title" />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <FormattedMessage id="login.failed.message" />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseErrorDialog}>ok</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    )
}

const loginSchema = yup.object().shape({
    username: yup.string().required(<FormattedMessage id="login.username.error" />),
    password: yup.string().required(<FormattedMessage id="login.password.error" />),
    captcha: yup.string().required(<FormattedMessage id="login.captcha.error" />),
})

const initialValuesLogin = {
    username: "",
    password: "",
    captcha: "",
}

export default Form