import React, { useState, useEffect } from 'react';
import axios from '../../utils/helper'
import { useAuthContext } from 'hooks/useAuthContext';
import { Box, Button, Typography, Grid, Card, CardHeader, Collapse, IconButton, Divider } from "@mui/material";
import { FormattedMessage } from 'react-intl';
import Header from "components/Header";
import DatePicker from 'react-datepicker'
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/calendar.css';
import '../../css/dashboard.css';
import '../../css/global.css';
// images
import SyncIcon from '../../assets/sync.png'
import DrawDateIcon from '../../assets/draw-date.png'
import TodayIcon from '../../assets/today.png'
import PrizeIcon from '../../assets/prize-pool.png'
import ActiveUserIcon from '../../assets/active-users.png'
import SoldUnitsIcon from '../../assets/sold-units.png'
import StrikeIcon from '../../assets/strike.png'
import SalesIcon from '../../assets/sales.png'
import CommissionIcon from '../../assets/commission-expenses.png'
import JackpotIcon from '../../assets/jackpot-expenses.png'
import ProfitIcon from '../../assets/profit.png'
import OrderIcon from '../../assets/order.png'
import TotalUnitIcon from '../../assets/total-unit.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const Dashboard = () => {

    const { user } = useAuthContext()
    const [expand, setExpand] = useState();
    const [expandTotalUnits, setExpandTotalUnits] = useState();
    const [expandTop10, setExpandTop10] = useState();

    const [dashData, setDashData] = useState({
        totalSales: 0,
        totalActive: 0,
        totalCommission: 0,
        totalJack: 0,
        totalUnits: 0,
        totalStrike: 0,
        totalProfit: 0,
        totalOrders: {},
        totalTop: [],
        payout: "",
        allUnits: [],
    })

    const getDashData = async () => {
        try {
            const { data } = await axios.get(`/api/dashboard`, { headers: { 'Authorization': `Bearer ${user.accessToken}` } })

            setDashData(data)

        } catch (error) {
            console.error(error)
        }
    }

    // Run Once
    useEffect(() => {
        getDashData()
    }, [])


    const orderData = [
        { label: '< 10', value: dashData.totalOrders?.orderTen || 0 },
        { label: '> 10', value: dashData.totalOrders?.orderHundred || 0 },
        { label: '> 100', value: dashData.totalOrders?.orderFHundred || 0 },
        { label: '> 500', value: dashData.totalOrders?.orderThousand || 0 }
    ];

    return (
        <Box m="1.5rem 1.5rem">

            <Box display='flex'>
                <Header title={<FormattedMessage id="dashboard" />} />
                <Box className='sync-box'>
                    <img src={SyncIcon} alt="refresh" className="sync-img" onClick={getDashData} />
                </Box>
            </Box>

            <Box className='default-section'>
                <Grid container>
                    <Grid item className='header' xs={12} sm={6} xl={4}>
                        <Box className='date-box'>
                            <Typography className='date-label'><FormattedMessage id='today' /></Typography>
                        </Box>
                        <Box display='flex'>
                            <Box className='date-image'>
                                <img src={TodayIcon} alt="today" className="date-img" />
                            </Box>
                            <Box sx={{ alignSelf: 'center', paddingLeft: '5px' }}>
                                <Typography className='date-text'>
                                    {new Date(dashData.report_at).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric"
                                    })}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item className='header' xs={12} sm={6} xl={4}>
                        <Box className='date-box'>
                            <Typography className='date-label'><FormattedMessage id='draw.date' /></Typography>
                        </Box>
                        <Box display='flex'>
                            <Box className='date-image'>
                                <img src={DrawDateIcon} alt="draw-date" className="date-img" />
                            </Box>
                            <Box sx={{ alignSelf: 'center', paddingLeft: '7px' }}>
                                <Typography className='date-text'>
                                    {new Date(dashData.drawDate).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric"
                                    })}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {dashData && (
                <>
                    <Box className="default-section">
                        <Grid container spacing={1} columns={12}>
                            <Grid item sm={6} md={6} xl={4} borderRight={{ sm: "1px solid #e3e4e6" }}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={PrizeIcon} alt="prize-pool" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.payout.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='accumulated.prize.pool' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} xl={4} borderRight={{ xl: "1px solid #e3e4e6" }}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={ActiveUserIcon} alt="prize-pool" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalActive}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='active.user' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} xl={4} >
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={SoldUnitsIcon} alt="prize-pool" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalUnits}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='unit.sold' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* <Grid item sm={6} md={6} xl={3}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={StrikeIcon} alt="prize-pool" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalStrike.toLocaleString()}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='strike' /></Typography>
                                    </Box>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Box>
                    <Box className="default-section">
                        <Grid container spacing={1} columns={12}>
                            <Grid item sm={6} md={6} xl={3} borderRight={{ sm: "1px solid #e3e4e6" }}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={SalesIcon} alt="sales" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='sales' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} xl={3} borderRight={{ xl: "1px solid #e3e4e6" }}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={CommissionIcon} alt="commission" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalCommission.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='commission' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} xl={3} borderRight={{ sm: "1px solid #e3e4e6" }}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={JackpotIcon} alt="jackpot" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalJack.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='jackpot' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} xl={3}>
                                <Box className="mainDataSection">
                                    <Box className="mainTextSection">
                                        <img src={ProfitIcon} alt="strike" className="mainDataIcon" />
                                    </Box>
                                    <Box className="mainTextSection">
                                        <Typography className="mainDataResult">{dashData.totalProfit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                        <Typography className="mainDataTitle"><FormattedMessage id='profit' /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="default-section-two">
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={6} xl={4}>
                                <Box className="collapsibleCardSection">
                                    <Card className="collapsibleCard">
                                        <CardHeader
                                            onClick={() => setExpand(!expand)}
                                            title={<Typography className="collapsibleCardTitle"><FormattedMessage id='orders' /></Typography>}
                                            action={
                                                <IconButton onClick={() => setExpand(!expand)}>
                                                    {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            }
                                        />
                                        <Collapse in={expand}>
                                            <Box paddingLeft={5} paddingRight={5} paddingBottom={5}>
                                                {dashData && orderData.map((item, index) => (
                                                    <Box display="flex" className="collapsibleCardInfoSection" key={index}>
                                                        <Typography className=""><img src={OrderIcon} alt="strike" className="collapsibleCardIcon" /></Typography>
                                                        <Typography className="collapsibleCardInfo">
                                                            <FormattedMessage id="orders" /> {item.label}
                                                        </Typography>
                                                        <Divider className="divider" orientation="vertical" flexItem />
                                                        <Typography className="collapsibleCardResult">{item.value}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <Box className="collapsibleCardSection">
                                    <Card className="collapsibleCard">
                                        <CardHeader
                                            onClick={() => setExpandTotalUnits(!expandTotalUnits)}
                                            title={<Typography className="collapsibleCardTitle"><FormattedMessage id='total.units' /></Typography>}
                                            action={
                                                <IconButton>
                                                    {expandTotalUnits ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            }
                                        />
                                        <Collapse in={expandTotalUnits}>
                                            <Box paddingLeft={5} paddingRight={5} paddingBottom={5}>
                                                {dashData.allUnits.map(unit => (
                                                    <Box display="flex" className="collapsibleCardInfoSection" key={unit._id}>
                                                        <Typography className=""><img src={TotalUnitIcon} alt="strike" className="collapsibleCardIcon" /></Typography>
                                                        <Typography className="collapsibleCardInfo">{unit._id ? <FormattedMessage id='unsold.units' /> : <FormattedMessage id='sold.units' />}</Typography>
                                                        <Divider className="divider" orientation="vertical" flexItem />
                                                        <Typography className="collapsibleCardResult">{unit.count}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} paddingBottom='30px'>
                                <Box className="collapsibleCardSection">
                                    <Card className="collapsibleCard">
                                        <CardHeader
                                            onClick={() => setExpandTop10(!expandTop10)}
                                            title={<Typography className="collapsibleCardTitle"><FormattedMessage id='top' /></Typography>}
                                            action={
                                                <IconButton>
                                                    {expandTop10 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            }
                                        />
                                        <Collapse in={expandTop10}>
                                            <Box paddingLeft={5} paddingRight={5} paddingBottom={5}>
                                                {dashData.totalTop.map((item, index) => (
                                                    <Box key={index} display="flex" className="collapsibleCardInfoSection">
                                                        <Typography className="collapsibleCardTop10"><FormattedMessage id={`top${index + 1}`} /></Typography>
                                                        <Divider className="divider_shorter" orientation="vertical" flexItem />
                                                        <Box className="top10BoxInBox">
                                                            <Typography className="collapsibleCardResult">{item.playerID}</Typography>
                                                            {/* If Next Time they want agentName also */}
                                                            {/* <Typography className="collapsibleCardResult">{item.agentName}</Typography> */}
                                                            <Divider className="divider_shorter_inBox" orientation="vertical" flexItem />
                                                            <Typography className="collapsibleCardResult">{item.units}</Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default Dashboard
