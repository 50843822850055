import axios from '../../utils/helper'
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { Box, useMediaQuery, FormLabel, Button, Typography, Modal, IconButton, TableContainer, TableHead } from "@mui/material"
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid"
import { FormattedMessage, useIntl } from "react-intl"
import Header from "components/Header"
import '../../css/schedule.css'
import '../../css/global.css'
import '../../css/calendar.css'
import '../../css/searchDialog.css'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteModal from 'components/DeleteModal';
import { ConfirmationModal } from 'components/Modal';

import CloseIcon from '@mui/icons-material/Close';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';


const Schedule = () => {
    const isNonMediumScreen = useMediaQuery("(min-width: 1200px)")
    const dataGridWidth = useMediaQuery("(max-width: 1200px)")
    const { user } = useAuthContext()

    const intl = useIntl()
    const availableButton = intl.formatMessage({ id: 'available.button' })
    const unavailableButton = intl.formatMessage({ id: 'unavailable.button' })
    const availableMessage = intl.formatMessage({ id: 'available.message' })
    const unavailableMessage = intl.formatMessage({ id: 'unavailable.message' })

    const { control: controlAddNewSchedule, handleSubmit: handleSubmitNewSchedule } = useForm()
    const [selectedNewDrawDate, setSelectedNewDrawDate] = useState(null);

    // delete
    const [selectedId, setSelectedId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const handleOpenDeleteModal = (id) => {
        setSelectedId(id)
        setOpenDeleteModal(true)
    }

    const handleCancel = () => {
        setOpenDeleteModal(false)
    }

    // status
    const [selectedAction, setSelectedAction] = useState('')
    const [selectedStatus, setSelectedStatus] = useState(null)

    const [openStateModal, setOpenStateModal] = useState(false);
    const handleOpenStateModal = (id, status, action) => {
        setSelectedId(id)
        setSelectedStatus(status)
        setSelectedAction(action)
        setOpenStateModal(true);
    }

    // log
    const [logData, setLogData] = useState(null);
    const [selectedLogId, setSelectedLogId] = useState(null);
    const [openLogDialog, setOpenLogDialog] = useState(false);

    const handleLogCancel = () => {
        setSelectedLogId(null);
        setOpenLogDialog(false);
    };

    const column = [
        {
            field: 'rowIndex',
            headerName: 'No',
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 80 : 110,
        },
        {
            field: 'drawDate',
            headerName: <FormattedMessage id="draw.date" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY"),
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 150 : 180,
        },
        {
            field: 'drawNum',
            headerName: <FormattedMessage id="sixd.num" />,
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 150 : 170,
        },
        {
            field: 'payout',
            headerName: <FormattedMessage id="accumulated.prize.pool" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 170 : 240,
        },
        {
            field: 'expenses',
            headerName: <FormattedMessage id="into.jackpot.pool.percentage" />,
            valueGetter: (params) => {
                const expenses = params.value || 0;
                return `${expenses} %`;
            },
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 150 : 180,
        },
        {
            field: 'record',
            headerName: <FormattedMessage id="log" />,
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 150 : 220,
            renderCell: (params) => (
                <Button
                    className='blue-button'
                    onClick={() => handleOpenLog(params.row._id)}
                >
                    <FormattedMessage id='activity' />
                </Button>
            ),
        },
        {
            field: 'action',
            headerName: <FormattedMessage id="action" />,
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 300 : 350,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        className={params.row.status ? 'green-button' : 'red-button'}
                        variant="contained"
                        onClick={() => handleOpenStateModal(params.row._id, params.row.status, params.row.status ? 'unavailable' : 'available')}
                        startIcon={params.row.status ? <CheckCircleOutlineIcon /> : <CancelIcon />}
                    >
                        {params.row.status ? availableButton : unavailableButton}
                    </Button>

                    <Button
                        className='red-button'
                        onClick={() => handleOpenDeleteModal(params.row._id)}
                    >
                        <FormattedMessage id='delete' />
                    </Button>
                </div>
            ),
        },
    ]

    const [pageState, setPageState] = useState({
        isLoading: false,
        schedule: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const fetchSchedules = async () => {
        try {
            const { data } = await axios.get(`/api/schedule/all?page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            const scheduleList = data.schedulesWithIntoJackpot.map((schedule, index) => ({
                ...schedule,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, schedule: scheduleList, total: data.pageTotal }))

        } catch (error) {
            console.error(error)
        }
    }

    const [firstTrueSchedule, setFirstTrueSchedule] = useState({
        drawDate: null,
        payout: null
    })

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
        const date = new Date(dateString)
        
        const formattedDate = date.toLocaleDateString(undefined, options)
        const [month, day, year] = formattedDate.split('/')
        
        return `${day}-${month}-${year}`
    }    

    const fetchFirstTrueSchedule = async () => {
        try {
            const response = await axios.get('/api/schedule/active', {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            const { drawDate, payout } = response.data || {}

            if (drawDate !== undefined && payout !== undefined) {
                setFirstTrueSchedule({ drawDate, payout })
            } else {
                console.error('Invalid data from server.')
            }
        } catch (error) {
            console.error('Error fetching first true schedule:', error)
        }
    }

    useEffect(() => {
        fetchSchedules()
        fetchFirstTrueSchedule()
    }, [pageState.page, pageState.pageSize])

    const handleSaveNewSchedule = async (date) => {
        try {

            const { data } = await axios.post(`/api/schedule/new`, {
                drawDate: date.selectedNewDrawDate
            }, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            fetchSchedules()

        } catch (error) {
            console.error(error)
        }
    }

    const handleChangeStatus = async () => {
        try {
            const { data } = await axios.patch(`/api/schedule/status`, {
                id: selectedId,
                status: selectedStatus
            }, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` },
            })

            setOpenStateModal(false)
            fetchSchedules()

        } catch (error) {
            console.error(error)
        }
    }

    const handleNoChangeStatus = () => {
        setOpenStateModal(false);
    }

    const handleDelete = async () => {
        try {
            const { data } = await axios.delete(`/api/schedule/delete?id=${selectedId}`,
                {
                    headers: { 'Authorization': `Bearer ${user.accessToken}` },
                })

            setOpenDeleteModal(false)
            fetchSchedules()

        } catch (error) {
            console.error(error)
        }
    }

    const handleOpenLog = async (logId) => {
        try {
            const { data } = await axios.get(`/api/schedule/log?id=${logId}`,
                {
                    headers: { 'Authorization': `Bearer ${user.accessToken}` },
                })

            setLogData(data)
            setOpenLogDialog(true)

        } catch (error) {
            console.error(error)
        }
    }

    const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '0.5px solid #A5CDF7',
                borderRadius: '0.3rem',
                width: '12rem',
                height: '2rem',
                padding: '0.2rem 0.5rem',
                fontSize: '13px',
                color: '#474747',
                fontWeight: 500,
                alignItems: 'center',
            }}
            onClick={onClick}
        >
            <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#2A75EA', marginRight: '4px' }} />
            <span style={{}}>{value}</span>
            {value && (
                <ClearIcon
                    sx={{ fontSize: '20px', color: '#999', cursor: 'pointer' }}
                    onClick={onClear}
                />
            )}
        </Box>
    ));

    // for datepicker
    const handleSelectedDrawDate = () => {
        setSelectedNewDrawDate(null)
    }

    return (
        <Box m="1.5rem 1.5rem">
            <Header title={<FormattedMessage id="schedules" />} />

            <Box
                mt="20px"
                display="grid"
                gridAutoRows="160px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="1rem 2rem 1rem 2rem"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >
                    {firstTrueSchedule.drawDate && firstTrueSchedule.payout ? (
                        <div>
                            <p><FormattedMessage id="next" />: {formatDate(firstTrueSchedule.drawDate)}</p>
                            <p><FormattedMessage id="accumulated.prize.pool" />: RM {firstTrueSchedule.payout.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                    ) : (
                        <p>N/A</p>
                    )}
                </Box>
            </Box>

            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                paddingBottom='30px'
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="fit-content"
                    backgroundColor="#FFFFFF"
                    p="2rem"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >
                    <form onSubmit={handleSubmitNewSchedule(handleSaveNewSchedule)}>
                        <Box borderBottom="1px solid #e3e4e6" style={{ width: "100%" }}>
                            <Typography className="section-title">
                                <FormattedMessage id="new.draw.date" />
                            </Typography>
                        </Box>

                        <Box className='newdraw-datefield'>
                            <Box className='sixd-component'>
                                <label className='calendar-label'><FormattedMessage id="select.date" />:</label>
                                <Controller
                                    control={controlAddNewSchedule}
                                    name="selectedNewDrawDate"
                                    defaultValue={null}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <DatePicker
                                            onChange={(date) => {
                                                const dateString = moment(date).format("YYYY-MM-DD")
                                                onChange(dateString)
                                                setSelectedNewDrawDate(dateString); // update selected date
                                            }}
                                            onBlur={onBlur}
                                            selected={value ? new Date(value) : null}
                                            showTimeSelect={false}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<CustomInput onClear={handleSelectedDrawDate} />}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className='sixd-component'>
                                <label className="cheat-box"> cheat </label>
                                <Button type="submit" className="go-button" variant='contained'><FormattedMessage id="add" /></Button>
                            </Box>
                        </Box>
                    </form>

                    <Box height='53vh'>
                        <DataGrid
                            rows={pageState.schedule}
                            columns={column}
                            getRowId={(row) => row._id}
                            paginationMode='server'
                            loading={pageState.isLoading}
                            rowCount={pageState.total}
                            initialState={{
                                pagination: { paginationModel: { pageSize: pageState.pageSize } },
                            }}
                            onPaginationModelChange={(pageState) => {
                                setPageState((old) => ({ ...old, page: pageState.page + 1, pageSize: pageState.pageSize }))
                            }}
                            // pageSizeOptions={[20]}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                border: 1,
                                borderColor: 'primary.light',
                                textAlign: 'left',
                                justifyContent: 'left',
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#f7f3e9',
                                },
                                [`& .${gridClasses.row}.even`]: {
                                    backgroundColor: '#2A75EA1C'
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    borderBottom: '1px solid #2A75EA'
                                },
                            }}
                        />
                    </Box>

                </Box>
            </Box>

            {openDeleteModal && (
                <DeleteModal
                    message='delete.confirmation.message'
                    onDelete={handleDelete}
                    onCancel={handleCancel}
                    onClose={handleCancel}
                />
            )}

            {openStateModal && (
                <ConfirmationModal
                    message={selectedAction === 'unavailable' ? unavailableMessage : availableMessage}
                    onLogout={handleChangeStatus}
                    onCancel={handleNoChangeStatus}
                    onClose={handleNoChangeStatus}
                />
            )}

            <Box>
                <Modal
                    open={openLogDialog}
                    onClose={handleLogCancel}
                >
                    {logData ? (
                        <Box className="log-dialog-box">
                            <Box className="searchBox-header">
                                <Box flexGrow={1} paddingLeft="40px" paddingBottom="15px" paddingTop="15px">
                                    <Typography color="green">Draw Date: {new Date(logData.drawDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</Typography>
                                </Box>
                                <IconButton>
                                    <CloseIcon onClick={handleLogCancel} />
                                </IconButton>
                            </Box>
                            <Box className="search-details">
                                <div style={{ overflowX: 'auto' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead className='table-head'>
                                                <TableRow className="borderedRow">
                                                    <TableCell className="tableCellWithBorder">
                                                        <Typography className="searchTitle">
                                                            <FormattedMessage id="number" />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="tableCellWithBorder">
                                                        <Typography className="searchTitle">
                                                            <FormattedMessage id="activity" />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="tableCellWithBorder">
                                                        <Typography className="searchTitle">
                                                            <FormattedMessage id="log.dateTime" />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="tableCellWithBorder">
                                                        <Typography className="searchTitle">
                                                            <FormattedMessage id="before" />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="tableCellWithBorder">
                                                        <Typography className="searchTitle">
                                                            <FormattedMessage id="after" />
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {logData.desc.map((item, index) => (
                                                    <TableRow key={index} className="borderedRow">
                                                        <TableCell className="tableCellWithBorder">
                                                            <Typography className="searchValue">{index + 1}</Typography>
                                                        </TableCell>
                                                        <TableCell className="tableCellWithBorder">
                                                            {item.details === 'drawNum' ? (
                                                                <Typography className="searchValue">
                                                                    <FormattedMessage id='drawNum' />
                                                                </Typography>
                                                            ) : item.details === 'payout' ? (
                                                                <Typography className="searchValue">
                                                                    <FormattedMessage id='payout' />
                                                                </Typography>
                                                            ) : item.details === 'expenses' ? (
                                                                <Typography className="searchValue">
                                                                    <FormattedMessage id='expenses' />
                                                                </Typography>
                                                            ) : (
                                                                <Typography className="searchValue">{item.details}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="tableCellWithBorder">
                                                            <Typography className="searchValue">
                                                                {new Date(item.changed_at).toLocaleString('en-US', {
                                                                    day: '2-digit',
                                                                    month: '2-digit',
                                                                    year: 'numeric',
                                                                    hour: 'numeric',
                                                                    minute: 'numeric',
                                                                    second: 'numeric',
                                                                    hour12: true
                                                                })}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="tableCellWithBorder">
                                                            {item.details === 'drawNum' ? (
                                                                <Typography className="searchValue">{item.before}</Typography>
                                                            ) : item.details === 'payout' ? (
                                                                <Typography className="searchValue">
                                                                    {parseFloat(item.before).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </Typography>
                                                            ) : item.details === 'expenses' ? (
                                                                <Typography className="searchValue">{item.before}%</Typography>
                                                            ) : (
                                                                <Typography className="searchValue">{item.before}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="tableCellWithBorder">
                                                            {item.details === 'drawNum' ? (
                                                                <Typography className="searchValue">{item.after}</Typography>
                                                            ) : item.details === 'payout' ? (
                                                                <Typography className="searchValue">
                                                                    {parseFloat(item.after).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </Typography>
                                                            ) : item.details === 'expenses' ? (
                                                                <Typography className="searchValue">{item.after}%</Typography>
                                                            ) : (
                                                                <Typography className="searchValue">{item.after}</Typography>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Box>
                            <Box className="ok-box">
                                <Button variant="contained" className="ok-button" onClick={handleLogCancel}>
                                    ok
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Typography variant="h5">
                            <FormattedMessage id="no.log.record" />
                        </Typography>
                    )}

                </Modal>
            </Box>

        </Box >
    )
}

export default Schedule